<template>
  <v-form ref="wordlistform" novalidate v-model="valid" @submit="addWordList">
    <v-text-field
      append-outer-icon="fa-plus"
      counter="255"
      data-lpignore="true"
      hint="(Enter to add)"
      label="New Word/Phrase"
      maxlength="255"
      ref="wordListInput"
      v-model="wordList"
      :rules="validate.wordListRules"
      @click:append-outer="addWordList()"
      ></v-text-field>
  </v-form>
</template>
<script>
import { Validate } from '../../Shared'
import API from '@/api'

export default {
  name: 'add-word-list',
  components: {
    Validate
  },

  data: () => ({
    wordList: '',
    valid: false,
    validate: Validate
  }),

  methods: {
    addWordList (e) {
      if (e !== undefined) {
        e.preventDefault()
      }
      let now = new Date()
      let datecreated = now.getFullYear() + '-' + ('0' + (now.getMonth() + 1)).slice(-2) + '-' + ('0' + now.getDate()).slice(-2)
      API.post('wordlist/create', {
        data: { wordlist: this.wordList, datecreated: datecreated },
        success: data => {
          this.$emit('wordListAdded', { DateCreated: datecreated, ID: data.ID, WordList: this.wordList })
          this.$refs.wordListInput.reset()
        },
        errorMessage: 'There was an error creating your word list.',
        rollbarMessage: 'Error creating word list',
        validateElem: this.$refs.wordlistform
      }, this)
    }
  }
}
</script>