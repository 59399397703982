<template>
  <v-bottom-nav :value="accountArea" fixed color="grey lighten-2">
    <v-btn flat color="secondary" value="dashboard" :to="{ name: 'Dashboard' }" exact>
      <span>Dashboard</span>
      <v-icon>fa-tachometer-alt</v-icon>
    </v-btn>
    <v-btn flat color="secondary" :to="{ name: 'CreateWordList' }" value="newwordlist">
      <span>Add Word/Phrase</span>
      <v-icon>fa-list</v-icon>
    </v-btn>
    <v-btn flat color="secondary" :to="{ name: 'CreateAlbum' }" value="newalbum">
      <span>Add Album</span>
      <v-icon>fa-compact-disc</v-icon>
    </v-btn>
    <v-btn flat color="secondary" :to="{ name: 'CreateSong' }" value="newsong">
      <span>Add Song</span>
      <v-icon>fa-music</v-icon>
    </v-btn>
  </v-bottom-nav>
</template>

<script>
export default {
  props: {
    accountArea: { type: Boolean, default: false }
  }
}
// add hidden style: https://webaim.org/techniques/css/invisiblecontent/
// color matcher: http://encycolorpedia.com/1d9b72
</script>