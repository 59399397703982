<template>
  <v-btn v-if="navItem.routeName" dark exact icon @click="" :to="{ name: navItem.routeName }">
    <v-icon>{{ navItem.icon }}</v-icon>
  </v-btn>
  <v-btn v-else-if="navItem.href && navItem.title != 'Store'" dark exact icon :href="navItem.href">
    <v-icon>{{ navItem.icon }}</v-icon>
  </v-btn>
  <v-btn v-else-if="navItem.href && navItem.title == 'Store'" class="cart" dark exact icon :href="navItem.href">
    <span :data-count="cartCount" v-if="cartCount > 0"></span>
    <v-icon>{{ navItem.icon }}</v-icon>
  </v-btn>
  <v-btn v-else dark exact icon @click="" :to="navItem.route">
    <v-icon>{{ navItem.icon }}</v-icon>
  </v-btn>
</template>

<script>
import API from '@/api'
export default {
  name: 'nav-icon',

  data: () => ({
    cartCount: 0
  }),

  created () {
    if (this.navItem.href && this.navItem.title === 'Store') {
      API.get('blog/cartdata/', {
        auth: false,
        rollbarMessage: 'Error getting cart data',
        success: (data) => {
          this.cartCount = data.count
          if (this.cartCount > 0) {
            this.navItem.href = '/blog/cart/'
          }
        }
      }, this, true)
    }
  },

  props: {
    navItem: { type: Object, required: true }
  }
}
</script>

<style scoped>
  a.cart > div > span:after {
    position:absolute;
    right:-10%;
    top: -20%;
    content: attr(data-count);
    padding:.3em;
    border-radius:90%;
    line-height:0.55em;
    background:#007095;
    text-align:center;
  }
</style>