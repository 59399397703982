<template>
  <div class="about">
    <section>
      <v-layout row>
        <v-flex>
          <h1 class="mb-2 display-2">About</h1>
          <img alt="Nic Lubbers" class="pl-2 portrait" src="./assets/Nic.jpg">
          <p>
            Hi, my name is Nic Lubbers. I started this project because I wanted online lyric writing software that would help me write songs 
            and I wanted to use my programming experience in a way that would help the music community. 
            This project seemed like a good way for me to accomplish both.
          </p>
          <p>
            I have loved music for as long as I can remember, and I know the impact a song can have on people. 
            Lyric writing can be a hard but very rewarding activity. 
            Some of my favorite songs were born out of the life experiences of the lyricists. 
            I want make it as easy as possible for lyricists to draw from their 
            experiences and bring songs of hope to their listeners.
          </p>
          <p>
            As a well can provide water that can be life giving or life taking, the lyrics of a song can bring life and hope 
            or suck the life and hope from its hearers. 
            Death and life are in the power of the tongue - use it wisely.
          </p>
          <h2 class="mb-2 display-1">Features</h2>
          <h3 class="headline">Stay Motivated</h3>
          <p>
            Whether it's something as large as writing a novel to something as small as saving a little money, one of the hardest things for many people 
            is finishing projects. To help you remember why you write lyrics, there is a section for you overall, in each album, and in each song 
            so you can write out your motivation. If there's no purpose to your song, you'll be less likely to finish it. 
            Having a reminder right in front of you as you write will help you press forward and keep writing, even when you don't feel like it. 
          </p>
          <h3 class="headline">Organize Your Lyric Writing</h3>
          <p>
            Use the thesaurus and rhyming dictionary to help find the words you need to write your message in your style. 
            Write notes and ideas about songs. 
            Keep your song ideas that don't fit into an album in the separate notebook area.
            Each album and song also have the option of adding a due date to help keep your writing on schedule.
          </p>
          <h3 class="headline">Practice Writing Lyrics</h3>
          <p>
            There are a number of exercises to help you practice and improve your lyric writing skills. 
            Random word exercises help improve your vocabulary and your ability to put words together. 
            Get better at writing concise songs with the Twitter challenge.
            Use the five minute exercises to get your daily writing in.
            Challenge yourself to write a song quickly with the Ten Minute Song exercise!
          </p>
          <h3 class="headline">Remember Your Inspiration</h3>
          <p>
            Keep lists of interesting words and phrases that you can look back on for future inspiration.
          </p>
          <h3 class="headline">Keep Your Creativity Flowing</h3>
          <p>
            Each day that you sign in you will get one of 365 lyric writing prompts taken from my <a href="/blog/product/prompt-lyrics/"><em>Prompt Lyrics</em></a> eBook.
          </p>
          <v-btn color="primary" @click="productClick">
            Start Your Free Trial
          </v-btn>
        </v-flex>
      </v-layout>
    </section>
    <!--<newsletter-signup></newsletter-signup>-->
  </div>
</template>

<script>
import GTM from '@/gtm'
// import { NewsletterSignup } from '../../Shared'

export default {
  /* components: {
    NewsletterSignup
  }, */
  created () {
    GTM.productImpression('About')
  },
  head: {
    title: {
      inner: 'About'
    },
    meta: [
      { n: 'twitter:title', c: 'About LyricWell' },
      { n: 'twitter:description', c: 'LyricWell features a thesaurus, rhyming dictionary, exercises, and tools to help keep you organized and motivated so you can finish your songs.' },
      { itemprop: 'name', c: 'About LyricWell' },
      { itemprop: 'description', c: 'LyricWell features a thesaurus, rhyming dictionary, exercises, and tools to help keep you organized and motivated so you can finish your songs.' },
      { p: 'og:title', c: 'About LyricWell' },
      { itemprop: 'og:description', c: 'LyricWell features a thesaurus, rhyming dictionary, exercises, and tools to help keep you organized and motivated so you can finish your songs.' },
      { p: 'og:image', c: 'https://lyricwell.com' + require('./assets/Nic.jpg') }
    ],
    script: [
      { src: '//widget.manychat.com/358139491060767.js', async: true }
    ]
  },
  methods: {
    productClick () {
      GTM.productClick('About')
      this.$router.push({
        name: 'FreeTrial'
      })
    }
  }
}
</script>
<style scoped>
  .about {
    max-width: 1200px;
  }
  .portrait {
    float: right;
  }
</style>