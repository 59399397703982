<template>
  <div>
    <!--
    @todo: check google analytics and add this based on screen size if needed
    <v-navigation-drawer absolute class="hidden-sm-and-up" overflow temporary v-model="drawer" v-if="!accountArea">
      <v-toolbar class="transparent" flat>
        <v-list class="pa-0">
          <v-list-tile avatar>
            <!--<v-list-tile-avatar>
              <img src="https://randomuser.me/api/portraits/men/85.jpg" />
            </v-list-tile-avatar>
            <v-list-tile-content>
              <v-list-tile-title>LyricWell</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
        </v-list>
      </v-toolbar>
      <v-list itemscope itemtype="http://www.schema.org/SiteNavigationElement" class="pt-0" dense>
        <v-divider></v-divider>
        <nav-tile v-for="item in sideNav" :navItem="item" :key="item.title"></nav-tile>
      </v-list>
    </v-navigation-drawer>-->
    <v-toolbar class="top-bar" color="secondary">
      <!--<v-toolbar-side-icon class="hidden-sm-and-up" @click.stop="drawer = !drawer" v-if="!accountArea"><v-icon>fa-bars</v-icon></v-toolbar-side-icon>-->
      <v-toolbar-title>
        <router-link to="/">
          <!--<img
              alt="LyricWell"
              srcset="./logo-sm.png 65w,
                   ./logo.png 131w"
              sizes="(max-width: 640px) 640px,
                  131px" src="./assets/logo-sm.png" />-->
          <img alt="LyricWell" src="./assets/logo.png" />
        </router-link>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <search v-if="accountArea"></search> 
      <top-nav :accountArea="accountArea"></top-nav>
    </v-toolbar>
  </div>
</template>

<script>
import NavTile from '../Nav/NavTile'
import NavIcon from '../Nav/NavIcon'
import Search from '../../Account/Shared/Search'
import TopNav from './TopNav'

export default {
  components: { NavTile, NavIcon, Search, TopNav },

  props: {
    accountArea: { type: Boolean, default: false }
  },

  data: () => ({
    drawer: null,
    loader: null,
    loading: false/* ,
    sideNav: [
      { title: 'Blog', icon: 'fa-newspaper', route: '/blog' },
      { title: 'About', icon: 'fa-info-circle', routeName: 'About' },
      { title: 'Store', icon: 'fa-shopping-cart', route: '/blog/product/break-your-creative-block-ebook' },
      { title: 'Log In', icon: 'fa-sign-in-alt', routeName: 'LogIn' }
    ] */
  })
}
</script>

<style scoped>
.top-bar {
  z-index: 2;
}
.top-bar img { height: 48px; }
</style>