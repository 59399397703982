<template>
  <div>
    <v-layout row wrap>
      <v-flex xs-12>
        <h1 class="mb-2 display-2">Account Cancelled</h1>
      </v-flex>
    </v-layout>
    <v-layout row>
      <v-flex xs12>
        <p>Your account has been cancelled.</p>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import Auth from '@/auth'

export default {
  head: {
    title: {
      inner: 'LyricWell Account Cancelled'
    },
    meta: [
      { name: 'description', content: 'LyricWell has the lyric writing tools and exercises to help you stay motivated, improve your lyric writing, and finish your songs. Sign up for a free trial today!' }
    ]
  },
  created () {
    Auth.clearSession(this)
  }
}
</script>