<template>
  <v-snackbar
    :color="toastColor"
    v-model="showToastMessage"
    :vertical="true"
    :timeout="6000"
  >
    {{ toastMessage }}
    <v-btn 
           dark
           flat
           v-if="typeof toastFunction === 'function'"
           @click="toastFunction"><v-icon small>{{ toastIcon }}</v-icon></v-btn>
    <v-btn 
           dark 
           flat 
           v-if="typeof toastFunction !== 'function'" 
           @click.native="$store.commit('HIDE_TOAST_MESSAGE')">Close</v-btn>
  </v-snackbar>
</template>


<script>
export default {
  computed: {
    showToastMessage: {
      get () {
        return this.$store.state.showToastMessage
      },
      set (newValue) {
        this.$store.commit('HIDE_TOAST_MESSAGE')
      }
    },
    toastColor () {
      return this.$store.state.toastColor
    },
    toastFunction () {
      return this.$store.state.toastFunction
    },
    toastIcon () {
      return this.$store.state.toastIcon
    },
    toastMessage () {
      return this.$store.state.toastMessage
    }
  }
}
</script>