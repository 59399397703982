<template>
  <div id="timer">
    <v-progress-circular
      :rotate="-90"
      :size="60"
      :width="5"
      :value="percentLeft"
      :color="color"
    >
      {{ minutes }}:{{ seconds }}
    </v-progress-circular>    
  </div>
</template>
<script>
// https://codepen.io/Chuloo/pen/xWVpqq
export default {
  props: {
    start: { type: Boolean, required: true },
    stop: { type: Boolean, required: true },
    time: { type: Number, required: true }
  },
  data () {
    return {
      color: 'green',
      timer: null,
      totalTime: (this.time * 60),
      percentLeft: 100
    }
  },
  beforeDestroy () {
    this.stopTimer()
  },
  // ========================
  methods: {
    startTimer () {
      this.timer = setInterval(() => this.countdown(), 1000)
    },
    stopTimer () {
      clearInterval(this.timer)
      this.timer = null
    },
    padTime (time) {
      return (time < 10 ? '0' : '') + time
    },
    countdown () {
      if (this.totalTime >= 1) {
        this.totalTime--
        this.percentLeft = this.totalTime / (this.time * 60) * 100
      } else {
        this.totalTime = 0
        this.percentLeft = this.totalTime / (this.time * 60) * 100
        this.$emit('completed')
      }

      if (this.percentLeft < 10) {
        this.color = 'red'
      } else if (this.percentLeft < 33) {
        this.color = 'orange'
      }
    }
  },
  // ========================
  computed: {
    minutes () {
      const minutes = Math.floor(this.totalTime / 60)
      return minutes
    },
    seconds () {
      const seconds = this.totalTime - (this.minutes * 60)
      return this.padTime(seconds)
    }
  },
  watch: {
    start () {
      if (this.start) { this.startTimer() }
    },
    stop () {
      if (this.stop) { this.stopTimer() }
    }
  }
}
</script>