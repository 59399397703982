<template>
  <div class="privacy-policy">
    <section>
      <v-layout
        row
      >
        <v-flex>
          <h1 class="display-2">Privacy Policy for LyricWell</h1>
          <p>
            This privacy policy discloses the privacy practices for <router-link :to="{ name: 'Home' }">https://lyricwell.com</router-link>. This privacy policy applies solely to information collected by this web site.
          </p>
          <h2 class="display-1">What information do we collect?</h2>
          <p>
            When you register on our site or fill out a form we may collect the following information:
            <ul>
              <li>Your name.</li>
              <li>Contact information including email address.</li>
              <li>Other information relevant to customer surveys and/or offers.</li>
            </ul>
          </p>
          <h2 class="display-1">What do we do with the information?</h2>
          <p>Any of the information we collect from you may be used in one of the following ways:</p>
          <ul>
            <li>To improve our website.</li>
            <li>To send you emails about site updates, company news or special offers.</li>
            <li>To administer a contest, promotion, survey or other site feature.</li>
          </ul>
          <p>We will never sell your information.</p>
          <h2 class="display-1">How do we protect your information?</h2>
          <p>We use a variety of security measures to maintain the safety of your personal information including protection by industry-standard 
            SSL (Secure Sockets Layer) encryption technology.</p>
          <h2 class="display-1">How do we use cookies?</h2>
          <p>We use traffic log cookies to identify which pages are being used so we can monitor which pages are useful and which are not. 
            We only use this information for statistical analysis purposes. A cookie in no way gives us access to your computer or any information about you, 
            other than the data you choose to share with us.</p>
          <p>You can choose to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser 
            setting to decline cookies if you prefer. This may prevent you from taking full advantage of the website.</p>
          <h2 class="display-1">Links to other websites</h2>
          <p>Our website may contain links to other sites. Please be aware that we are not responsible for the content or privacy practices of these sites. 
            We encourage our users to be aware when they leave our site and to read the privacy statements of any other site that collects personally identifiable 
            information.</p>
            <h2 class="display-1">Surveys &amp; Contests</h2>
            <p>From time to time we may request information via surveys. Participation in these surveys is completely voluntary so you may choose whether 
              or not to participate and therefore disclose this information. Information requested may include contact information and demographic information. 
              Survey information will be used for purposes of monitoring or improving the use and satisfaction of this site.</p>
            <h2 class="display-1">Your Consent</h2>
            <p>By using our website, you hereby consent to our privacy policy and agree to its terms.</p>
            <h2 class="display-1">Update</h2>
            <p>This Privacy Policy was last updated on: Tuesday, February 3rd, 2015.<br>Should we update, amend or make any changes to our privacy policy, 
              those changes will be posted here.</p>
        </v-flex>
      </v-layout>
    </section>
    <!--<newsletter-signup></newsletter-signup>-->
  </div>
</template>
<script>
// import { NewsletterSignup } from '../../Shared'

export default {
  /* components: {
    NewsletterSignup
  },
  */
  head: {
    title: {
      inner: 'LyricWell Online Privacy Policy'
    },
    meta: [
      { name: 'description', content: 'Privacy Policy for LyricWell.com' }
    ],
    script: [
      { src: '//widget.manychat.com/358139491060767.js', async: true }
    ]
  }
}
</script>
<style scoped>
  .privacy-policy {
    max-width: 1000px;
  }
  .portrait {
    float: right;
    padding-left: 15px;
  }
</style>