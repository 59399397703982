<template>
  <div>
    <v-layout row>
      <v-flex xs12>
        <!-- {if $smarty.session.Account.AccountTypeID!=7} -->
        <h1 class="mb-2 display-1">Lyric Writing Exercises</h1>
        <p>Here you will find exercises to help you improve or jump start your lyric writing.</p>
      </v-flex>
    </v-layout>
    
    <v-container fluid grid-list-md>
      <v-layout row wrap>
        <v-flex xs12 sm6 lg4 xl3>
          <v-card class="blue-grey lighten-5" :to="{ name: 'ExerciseTwitter' }">
            <v-card-title primary-title>
              <div class="headline">The Twitter Challenge <v-icon>fab fa-twitter</v-icon></div>
              <div>
                <span class="grey--text">No time limit</span>
                <p>Write ten "Twitter (short) songs" to complete a twitter album.</p>
              </div>
            </v-card-title>
          </v-card>
        </v-flex>
        <v-flex xs12 sm6 lg4 xl3>
          <v-card class="blue-grey lighten-5" :to="{ name: 'ExerciseTenMinuteSong' }">
            <v-card-title primary-title>
              <div class="headline">Ten Minute Song <v-icon>fa-clock</v-icon></div>
              <div>
                <span class="grey--text">10 minutes</span>
                <p>Try to finish a song before the timer runs out.</p>
            </div>
            </v-card-title>
          </v-card>
        </v-flex>
        <v-flex xs12 sm6 lg4 xl3>
          <v-card class="blue-grey lighten-5" :to="{ name: 'ExerciseSingleWord' }">
            <v-card-title primary-title>
              <div class="headline">Single Word Challenge <v-icon>fa-tasks</v-icon></div>
              <div>
                <span class="grey--text">5 minutes</span>
                <p>Create as many lines of lyrics as you can using one randomly generated word.</p>
              </div>
            </v-card-title>
          </v-card>
        </v-flex>
        <v-flex xs12 sm6 lg4 xl3>
          <v-card class="blue-grey lighten-5" :to="{ name: 'ExerciseDoubleWord' }">
            <v-card-title primary-title>
              <div class="headline">Double Word Challenge <v-icon>fa-tasks</v-icon></div>
              <div>
                <span class="grey--text">10 minutes</span>
                <p>Create as many lines of lyrics as you can using two randomly generated words as the start and end of each line.</p>
              </div>
            </v-card-title>
          </v-card>
        </v-flex>
        <v-flex xs12 sm6 lg4 xl3>
          <v-card class="blue-grey lighten-5" :to="{ name: 'ExerciseTenWord' }">
            <v-card-title primary-title>
              <div class="headline">Ten Word Challenge <v-icon>fa-tasks</v-icon></div>
              <div>
                <span class="grey--text">15 minutes</span>
                <p>Create a song using ten randomly generated words.</p>
              </div>
            </v-card-title>
          </v-card>
        </v-flex>
        <v-flex xs12 sm6 lg4 xl3>
          <v-card class="blue-grey lighten-5" :to="{ name: 'ExerciseFreeWriting' }">
            <v-card-title primary-title>
              <div class="headline">Free Writing <v-icon>fa-pencil-alt</v-icon></div>
              <div>
                <span class="grey--text">5 minutes</span>
                <p>Write words, any words.<!--http://nicholastozier.com/got-five-minutes-then-youve-got-time-to-write/--></p>
              </div>
            </v-card-title>
          </v-card>
        </v-flex>
        <v-flex xs12 sm6 lg4 xl3>
          <v-card class="blue-grey lighten-5" :to="{ name: 'ExerciseImageOnlyFreeWriting' }">
            <v-card-title primary-title>
              <div class="headline">Image-Only Free Writing <v-icon>fa-image</v-icon></div>
              <div>
                <span class="grey--text">5 minutes</span>
                <p>
                  Write only sensory words.
                  <!-- http://nicholastozier.com/songwriting-prompts-and-lyric-writing-exercises/lyric-writing-prompt-imagist-free-writing/ -->
                </p>
              </div>
            </v-card-title>
          </v-card>
        </v-flex>
      <!-- {else}
        You do not have access to this area.
      {/if} -->
      </v-layout>
    </v-container>
  </div>
</template>
<script>
export default {
  head: {
    title: {
      inner: 'Lyric Writing Exercises'
    }
  }
}
</script>
<style scoped>
  .headline { width: 100%; }
  .headline i { float: right; }
</style>