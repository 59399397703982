<template>
  <v-flex xs12 sm6 md3 v-if="index < 3">
    <v-card :class="color + ' lighten-' + (5 - index)" :to="'/' + listType + '/' + action + '/' + listItem.id">
      <v-card-title class="body-2">{{ listItem.title }}</v-card-title>
      <v-card-text>{{ listItem.text | truncate(190) }}</v-card-text>
      <v-progress-linear v-if="listItem.percent" v-model="listItem.percent" :color="progressColor"></v-progress-linear>
    </v-card>
  </v-flex>
  <v-flex v-else sm6 md3>
    <v-card :class="color + ' hidden-xs-only lighten-' + (5- index)" :to="'/' + listType + '/' + action + '/' + listItem.id">
      <v-card-title class="body-2">{{ listItem.title }}</v-card-title>
      <v-card-text>{{ listItem.text | truncate(190) }}</v-card-text>
      <v-progress-linear v-if="listItem.percent" v-model="listItem.percent" :color="progressColor"></v-progress-linear>
    </v-card>
  </v-flex>
</template>

<script>
export default {
  props: {
    index: { type: Number, required: true },
    listItem: { type: Object, required: true },
    listType: { type: String, required: true },
    color: { type: String, required: false, default: 'blue-grey' }
  },
  data () {
    let percent = parseInt(this.listItem.percent)
    return {
      progressColor: (percent >= 70 ? 'success' : (percent >= 30 ? 'warning' : 'error')),
      action: (this.listType === 'wordlist' ? 'view' : 'edit')
    }
  }
}
</script>