<template>
  <v-footer
    height="auto"
    color="secondary lighten-2"
  >
    <v-layout
      row
      wrap
      v-if="!accountArea"
    >
      <v-btn color="white" flat :to="{ name: 'Home' }" exact>Home</v-btn>
      <v-btn color="white" flat href="/blog">Blog</v-btn>
      <v-btn color="white" flat :to="{ name: 'FreeTrial' }">Free Trial</v-btn>
      <v-btn color="white" flat :to="{ name: 'About' }">About</v-btn>
      <v-btn color="white" flat :to="{ name: 'Contact' }">Contact Us</v-btn>
      <v-btn color="white" flat href="/blog/store/">Store</v-btn>
      <v-btn color="white" flat :to="{ name: 'PrivacyPolicy' }">Privacy Policy</v-btn>
      <v-spacer></v-spacer>
      <div class="mr-5 mt-3 white--text">&copy; {{ new Date().getFullYear() }}</div>
    </v-layout>
  </v-footer>
</template>

<script>
export default {
  name: 'site-footer',

  props: {
    accountArea: { type: Boolean, default: false }
  }
}
</script>

<style scoped>
.footer ul{
  list-style: none;
  padding-left: 0;
}
.footer li{
  display: inline;
  margin-right: 1em;
}
</style>