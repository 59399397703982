<template>
  <div>
    <v-layout row wrap>
      <v-flex xs-12>
        <h1 class="mb-2 display-2">Request a Password Reset</h1>
        <p>
          Enter your email address. You will receive an email with a link to reset your password.
        </p>
      </v-flex>
    </v-layout>
    <v-layout row>
      <v-flex xs11 sm6 md4 lg3 xl2>
        <v-form v-model="valid" ref="form" novalidate @submit="submit">
          <v-text-field
            data-lpignore="true"
            label="Email"
            v-model="account"
            :rules="validate.loginEmailRules"
            required
          ></v-text-field>
          <v-btn color="primary" type="submit">Reset</v-btn>
        </v-form>
      </v-flex>
      <v-flex xs1 sm6 md8 lg9 xl10></v-flex>
    </v-layout>
  </div>
</template>
<script>
import API from '@/api'
import { Validate } from '../../Shared'

export default {
  data () {
    return {
      account: '',
      valid: false,
      validate: Validate
    }
  },

  head: {
    title: {
      inner: 'Reset Your LyricWell Password'
    },

    meta: [
      { name: 'description', content: 'Request a password reset for your LyricWell account.' }
    ]
  },

  methods: {
    submit (e) {
      e.preventDefault()
      API.post('accounts/resetpasswordrequest', {
        auth: false,
        data: { account: this.account },
        errorMessage: 'There was an error sending your password reset email.',
        rollbarMessage: 'Error sending password reset',
        validateElem: this.$refs.form,
        success: data => {
          this.$store.commit('SET_TOAST_MESSAGE', { message: 'Please follow the instructions in your email to reset your password.', color: 'success' })
        }
      }, this)
    }
  }
}
</script>