<template>
  <v-form v-model="valid" ref="form" novalidate @submit="update">
    <v-container grid-list-md>
      <v-layout row wrap>
        <v-flex xs12 sm6>
          <v-text-field
            label="Password"
            v-model="password"
            :rules="validate.loginPasswordRules"
            type="password"
          ></v-text-field>
        </v-flex>
        <v-flex xs12 sm6>
          <v-text-field
            append-icon="fa-envelope"
            data-lpignore="true"
            label="New Email Address"
            ref="account"
            type="email"
            v-model="account"
            :error-messages="accountErrors"
            :rules="validate.emailRules"
            @keyup="duplicatesChecked = false"
            @focus="clearAccountErrors"
          ></v-text-field>
        </v-flex>
        <v-flex class="text-xs-right" xs12>
          <v-btn color="primary" type="submit">Update</v-btn>
        </v-flex>
      </v-layout>
    </v-container>
  </v-form>
</template>

<script>
// https://github.com/algolia/vue-instantsearch algolia search component
import API from '@/api'
import { Validate } from '../../Shared'

export default {
  components: {
    Validate
  },

  data: () => ({
    account: '',
    accountErrors: [],
    password: '',
    valid: false,
    duplicatesChecked: false,
    validate: Validate
  }),

  methods: {
    accountValidation () {
      API.post('accounts/exists', {
        auth: false,
        data: { account: this.account },
        rollbarMessage: 'Error attempting to validate email address',
        validateElem: this.$refs.account,
        alert: alerts => {
          if (alerts && alerts.Errors && alerts.Errors.Field && alerts.Errors.Field.account) {
            if (alerts.Errors.Field.account === true) {
              this.accountErrors = [ 'An account with that email address already exists' ]
            } else {
              this.accountErrors = alerts.Errors.Field.account
            }
          }
        },
        success: data => {
          this.duplicatesChecked = true
          this.update()
        }
      }, this)
    },

    clearAccountErrors () {
      if (this.$refs.account.hasError) {
        this.accountErrors = []
      }
    },

    update (e) {
      if (e !== undefined) {
        e.preventDefault()
      }
      if (!this.$refs.account.hasError && this.duplicatesChecked) {
        console.log('submitting')
        API.put('account/email', {
          data: { account: this.account, password: this.password },
          errorMessage: 'There was an error updating your email',
          rollbarMessage: 'Error attempting to update email',
          validateElem: this.$refs.form,
          success: data => {
            this.$store.commit('SET_TOAST_MESSAGE', { message: 'Please check your email to verify your new email address.', color: 'success' })
          }
        }, this)
      } else if (!this.duplicatesChecked) {
        this.accountValidation()
      }
    }
  }
}
</script>