<template>
  <v-bottom-sheet v-model="visible">
    <v-container grid-list-md class="white">
      <v-layout class="ml-2" row wrap>
        <v-flex xs12 v-if="!subscribed">
          <h2 class="headline">Have you reached your creative potential?</h2>
          <v-alert color="error" icon="fa-exclamation-triangle" v-for="error in errors" v-model="errors" :key="error">
            {{ error }}
          </v-alert>
          <v-alert color="info" icon="fa-info-circle" v-for="message in messages" v-model="messages" :key="message">
            {{ message }}
          </v-alert>
        </v-flex>
        <v-flex xs12 sm5 v-if="!subscribed">
          <p>Sign up to receive 10 weekly creative exercises from the Break Your Creative Block eBook</p>
          <v-form novalidate ref="form" v-model="valid" @submit="submit">
            <v-text-field
              autofocus
              append-icon="fa-user"
              label="Name"
              v-model="name"
              :rules="validate.nameRules"
              required
            ></v-text-field>
            <v-text-field
              append-icon="fa-envelope"
              label="Email"
              v-model="email"
              :rules="validate.emailRules"
              type="email"
              required
            ></v-text-field>
            <v-btn color="primary" type="submit">Sign Me Up!</v-btn>
            <p class="mt-5 ml-2 caption"><a href="javascript:;" @click="hide">No Thanks</a></p>
          </v-form>
        </v-flex>
        <v-flex v-else>
          <p class="text-xs-right"><v-icon right @click="hidePopup">fa-close</v-icon></p>
          <v-alert color="success" icon="fa-check-circle" value="true">Thank you for subscribing, check your email to get started!</v-alert>
        </v-flex>
        <v-flex class="pl-3" xs12 sm5 v-if="!subscribed">
          <img src="../assets/BreakYourCreativeBlock-sm.png" />
        </v-flex>
      </v-layout>
    </v-container>
  </v-bottom-sheet>
</template>

<script>
import { hideNewsletter, showNewsletter, subscribeNewsletter } from '../newsletter'
import { Validate } from '../../Shared'

export default {
  name: 'newsletter-sign-up',

  components: {
    Validate
  },

  data () {
    return {
      visible: showNewsletter(this),
      valid: false,
      subscribed: false,
      errors: [],
      messages: [],
      name: '',
      email: '',
      validate: Validate
    }
  },

  methods: {
    hide () {
      hideNewsletter(this)
    },
    hidePopup () {
      this.visible = false
    },
    submit (e) {
      e.preventDefault()
      if (this.$refs.form.validate()) {
        subscribeNewsletter(this)
      }
    }
  }
}
</script>

<style>
.application--light .standard{
  background-color: #007095 !important;
  color: #fff !important;
}
</style>