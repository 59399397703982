import Vuex from 'vuex'
import Vue from 'vue'
import Rollbar from 'vue-rollbar' // https://rollbar.com/docs/notifier/rollbar.js/ (debug, info, warning, error, critical)
import { SET_TOAST_MESSAGE, HIDE_TOAST_MESSAGE, HANDLE_API_ALERTS,
  SET_USER_AUTHENTICATED, SET_USER_INFORMATION, SET_AUTHENTICATION_INITIALIZED } from './mutation-types'

Vue.use(Rollbar, {
  accessToken: 'bfad6687452a4053b60f8801e52763c1',
  captureUncaught: true,
  payload: {
    environment: 'development'
  }
})

Vue.use(Vuex)

export const store = new Vuex.Store({
  state: {
    toastMessage: '',
    toastIcon: 'fa-undo',
    toastFunction: null,
    toastColor: 'error',
    showToastMessage: false,

    user: {
      accounttype: '',
      firstname: '',
      lastname: '',
      authenticated: false,
      authenticationInitialized: false,
      searchid: ''
    }
  },
  mutations: {
    [SET_TOAST_MESSAGE] (state, payload) {
      state.toastMessage = payload.message
      if (payload.color !== undefined) {
        state.toastColor = payload.color
      } else {
        state.toastColor = 'error'
      }

      if (payload.function !== undefined) {
        state.toastFunction = payload.function
      } else {
        state.toastFunction = null
      }

      if (payload.toastIcon !== undefined) {
        state.toastIcon = payload.icon
      } else {
        state.toastIcon = 'fa-undo'
      }

      state.showToastMessage = true
    },
    [HANDLE_API_ALERTS] (state, payload) {
      let alerts = payload.Alerts
      if (alerts.Errors !== undefined && alerts.Errors.length > 0) {
        state.toastColor = 'error'
        state.toastMessage = alerts.Errors[0]
      } else if (alerts.Warnings !== undefined && alerts.Warnings.length > 0) {
        state.toastColor = 'info'
        state.toastMessage = alerts.Warnings[0]
      } else if (alerts.Messages !== undefined && alerts.Messages.length > 0) {
        state.toastMessage = alerts.Messages[0]
        state.toastColor = 'success'
      }

      state.showToastMessage = true
    },
    [HIDE_TOAST_MESSAGE] (state) {
      state.showToastMessage = false
    },
    [SET_USER_AUTHENTICATED] (state, authenticated) {
      state.user.authenticated = authenticated
    },
    [SET_AUTHENTICATION_INITIALIZED] (state) {
      state.user.authenticationInitialized = true
    },
    [SET_USER_INFORMATION] (state, payload) {
      if (payload.user !== undefined) {
        if (payload.user.firstname !== undefined) {
          state.user.firstname = payload.user.firstname
        }
        if (payload.user.lastname !== undefined) {
          state.user.lastname = payload.user.lastname
        }
        if (payload.user.searchid !== undefined) {
          state.user.searchid = payload.user.searchid
        }
        if (payload.user.accounttype !== undefined) {
          state.user.accounttype = payload.user.accounttype
        }
      }
    }
  }
})
