<template>
  <div>
    <link rel="preconnect" href="https://lyricwell.com">
    <section>
      <v-parallax class="test" src="static/img/mic.jpg" alt="Microphone background" height="600">
        <v-layout
          align-center
          hidden-md-and-up
          justify-center
          row
        >
          <!--<v-flex xs7 class="composer"><img alt="Sign up for LyricWell" class="composer" src="./assets/composer.png"></v-flex>-->
          <v-flex xs12 mt-5>
            <h1 class="black--text mb-2 mt-5 display-2">LyricWell</h1>
            <div class="black--text headline mb-3 text-xs-center"><!-- Tell your story -->Write lyrics, change lives.</div>
            <v-btn color="primary" :to="{ name: 'Dashboard' }" v-if="loggedIn">
              Go To Your Dashboard
            </v-btn>
            <v-btn color="primary" @click="productClick" v-if="!loggedIn">
              Start Your Free Trial
            </v-btn><br v-if="!loggedIn" />
            <!-- @todo: make this open the small login form -->
            <router-link :to="{ name: 'LogIn' }" v-if="!loggedIn">or Log In</router-link>
          </v-flex>
        </v-layout>
        <v-layout
          align-center
          hidden-sm-and-down
          justify-center
          row
        >
          <!--<v-flex xs7 class="composer"><img alt="Sign up for LyricWell" class="composer" src="./assets/composer.png"></v-flex>-->
          <v-flex xs12 offset-md3 offset-lg0>
            <h1 class="black--text mb-2 display-2">LyricWell</h1>
            <div class="black--text headline mb-3 text-xs-center"><!-- Tell your story -->Write lyrics, change lives.</div>
            <v-btn color="primary" :to="{ name: 'Dashboard' }" v-if="loggedIn">
              Go To Your Dashboard
            </v-btn>
            <v-btn color="primary" @click="productClick" v-if="!loggedIn">
              Start Your Free Trial
            </v-btn><br v-if="!loggedIn" />
            <!-- @todo: make this open the small login form -->
            <router-link :to="{ name: 'LogIn' }" v-if="!loggedIn">or Log In</router-link>
          </v-flex>
        </v-layout>
      </v-parallax>
    </section>

    <section>
      <v-layout
        column
        wrap
        class="my-5"
        align-center
      >
        <v-flex xs12 sm4 class="my-3">
          <div class="text-xs-center">
            <h2 class="display-1">Want to finish more songs?</h2>
            <span class="subheading">
              LyricWell is here to help
            </span>
          </div>
        </v-flex>
        <v-flex xs12>
          <v-container grid-list-xl>
            <v-layout row wrap align-center>
              <v-flex xs12 sm4>
                <v-card class="elevation-0 transparent">
                  <v-card-text class="text-xs-center">
                    <v-icon x-large class="blue--text text--lighten-2">fa-question-circle</v-icon>
                  </v-card-text>
                  <v-card-title primary-title class="layout justify-center">
                    <div class="headline text-xs-center">Stay Motivated</div>
                  </v-card-title>
                  <v-card-text>
                    Why do you write lyrics? What motivated you to start that song?
                    <!--There are times where you HAVE to write and other times where you don't want to write.-->
                    LyricWell keeps your answers to those questions in front of you so you remember why you write, even when you don't want to.
                  </v-card-text>
                </v-card>
              </v-flex>
              <v-flex xs12 sm4>
                <v-card class="elevation-0 transparent">
                  <v-card-text class="text-xs-center">
                    <v-icon x-large class="blue--text text--lighten-2"><!--fa-book fa-wrench fa-check-circle-->fa-calendar-check</v-icon>
                  </v-card-text>
                  <v-card-title primary-title class="layout justify-center">
                    <div class="headline">Write Consistently</div>
                  </v-card-title>
                  <v-card-text>
                    Use the thesaurus and rhyming dictionary to help find the words you need to write your message in your style.
                    Add due dates to keep your writing on schedule.
                    Write notes and ideas about songs.
                    Keep lists of interesting words or phrases.
                  </v-card-text>
                </v-card>
              </v-flex>
              <v-flex xs12 sm4>
                <v-card class="elevation-0 transparent">
                  <v-card-text class="text-xs-center">
                    <v-icon x-large class="blue--text text--lighten-2"><!--fa-graduation-cap-->fa-pen-square</v-icon>
                  </v-card-text>
                  <v-card-title primary-title class="layout justify-center">
                    <div class="headline text-xs-center">Practice</div>
                  </v-card-title>
                  <v-card-text>
                    Improve your vocabulary with one of the random word exercises.
                    Shorten up your songs with the Twitter song challenge.
                    Get your daily writing in with a five minute exercises.
                    See how fast you can write a song with the ten minute song challenge!
                  </v-card-text>
                </v-card>
              </v-flex>
            </v-layout>
          </v-container>
        </v-flex>
      </v-layout>
    </section>

    <section>
      <v-parallax class="testimonial" src="static/img/river.jpg" alt="River background" height="475">
        <v-layout column align-center justify-center pa-4>
          <div class="headline grey--text text--darken-4 mb-3">Keep Your Creativity Flowing</div>
          <blockquote class="grey--text text--darken-4 text-xs-left">
            <p>
              I found the rhyme and thesaurus tools to be invaluable to my songwriting process. I especially found them useful when storyboarding for songs.
              I would type in concepts to see what kind of words would come up to prompt my inspiration.
            </p>
            <p>
              I was in a time-crunch when I started using LyricWell. I needed to finish a song by the next day, and my creativity was waning.
              Just by typing in word concepts into the Thesaurus/Rhyme tools, I found the inspiration I needed to finish the song.
            </p>
            <em>- Kevin Embleton (<a href="http://www.embletonmusic.com" target="_blank">Embleton</a>)</em>
          </blockquote>
          <em class="grey--text text--darken-4">Kick-start your lyric writing today</em>
          <v-btn color="primary" :to="{ name: 'Dashboard' }" v-if="loggedIn">
            Go To Your Dashboard
          </v-btn>
          <v-btn color="primary" @click="productClick" v-if="!loggedIn">
            Sign Up
          </v-btn>
        </v-layout>
      </v-parallax>
    </section>

    <blog-post-list :postCount="postCount"></blog-post-list>

    <!--<newsletter-signup></newsletter-signup>-->
  </div>
</template>
<script>
import Auth from '@/auth'
import BlogPostList from './BlogPostList/BlogPostList'
import GTM from '@/gtm'
// import { NewsletterSignup } from '../../Shared'

export default {
  components: {
    BlogPostList
    // NewsletterSignup
  },

  created () {
    GTM.productImpression('Home')
    if (Auth.checkAuth(this)) {
      this.loggedIn = true
    }
  },

  data: () => ({
    loggedIn: false,
    postCount: 2
  }),

  head: {
    title: {
      inner: 'Write Lyrics, Change Lives'
    },
    meta: [
      { n: 'twitter:title', c: 'LyricWell | Write Lyrics, Change Lives' },
      { n: 'twitter:description', c: 'LyricWell has lyric writing tools and exercises to help you stay motivated, improve your lyric writing, and finish your songs. Sign up for a free trial today!' },
      { itemprop: 'name', c: 'LyricWell | Write Lyrics, Change Lives' },
      { itemprop: 'description', c: 'LyricWell has lyric writing tools and exercises to help you stay motivated, improve your lyric writing, and finish your songs. Sign up for a free trial today!' },
      { p: 'og:title', c: 'LyricWell | Write Lyrics, Change Lives' },
      { itemprop: 'og:description', c: 'LyricWell has lyric writing tools and exercises to help you stay motivated, improve your lyric writing, and finish your songs. Sign up for a free trial today!' },
      { p: 'og:image', c: 'https://lyricwell.com' + require('./../../../../static/img/mic.jpg') }
    ],
    script: [
      { src: '//widget.manychat.com/358139491060767.js', async: true }
    ]
  },

  methods: {
    productClick () {
      GTM.productClick('Home')
      this.$router.push({
        name: 'FreeTrial'
      })
    }
  }
}
</script>
<style>
  main.home .v-parallax__image-container {
    opacity: 0.5;
  }
  main.home .testimonial .v-parallax__image-container {
    opacity: 0.2;
  }
  @media (max-width: 1263px) {
    main.home .v-parallax__image {
      left: 830px;
    }
  }
  main.home {
    text-align: center;
    margin: 0;
  }
  main.home .container {
    margin: 0;
    padding: 0;
    max-width: 100%;
  }
</style>
<!--
<style scoped>
  .composer{
    text-align: left;
  }
  .composer img{
    margin: 0 0 -206px -14px;
  }
</style>
-->