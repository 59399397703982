// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import '@fortawesome/fontawesome-free/css/all.min.css'
import Vue from 'vue'
import Vuetify from 'vuetify' // https://vuetifyjs.com/
import App from './App'
import { store } from './store'
import router from './router'
import VueHead from 'vue-head'
// import fetchival from 'fetchival' // https://github.com/typicode/fetchival
import VueLocalStorage from 'vue-ls' // https://github.com/RobinCK/vue-ls - local storage so blog posts are only fetched daily and for other storage (token, etc.)
import Rollbar from 'vue-rollbar' // https://rollbar.com/docs/notifier/rollbar.js/ (debug, info, warning, error, critical)

// import InstantSearch from 'vue-instantsearch' // algolia
import { Highlight, Index, NoResults, Results } from 'vue-instantsearch'

// Vue.config.productionTip = false
// @todo: set up vue-gtm: https://www.npmjs.com/package/vue-gtm

let environment = 'production'
switch (window.location.hostname) {
  case 'lyricwell-niclubbersmusic761690.codeanyapp.com':
  case 'localhost':
    environment = 'development'
    break
  case 'beta.lyricwell.com':
    environment = 'staging'
    break
}

Vue.use(Rollbar, {
  accessToken: 'bfad6687452a4053b60f8801e52763c1',
  captureIP: 'anonymize',
  captureUncaught: true,
  captureUnhandledRejections: true,
  enabled: true,
  payload: {
    environment: environment,
    client: {
      javascript: {
        code_version: '1.5'
      }
    }
  }
})
Vue.use(VueHead, {
  complement: 'LyricWell'
})
Vue.use(Vuetify, {
  iconfont: 'fa',
  theme: {
    primary: '#007095',
    secondary: '#1D9B72',
    success: '#1D9B72'
  }
})
Vue.use(VueLocalStorage, {
  namespace: 'vuejs__'
})

// Vue.use(InstantSearch)
Vue.component('ais-highlight', Highlight)
Vue.component('ais-index', Index)
Vue.component('ais-no-results', NoResults)
Vue.component('ais-results', Results)

let filter = function (text, length, clamp) {
  clamp = clamp || '...'
  let node = document.createElement('div')
  node.innerHTML = text
  let content = node.textContent

  if (content.length > length) {
    content = content.slice(0, length)
  }

  if (content.indexOf('.') !== -1) {
    content = content.slice(0, content.indexOf('.') + 1)
  } else {
    content = content + clamp
  }

  return content
}

Vue.filter('truncate', filter)

Vue.config.productionTip = false
// Vue.prototype.$http = fetchival('https://lyricwell-niclubbersmusic761690.codeanyapp.com/api/v1', { mode: 'cors' })

require('vuetify/dist/vuetify.min.css')
require('vuetify/dist/vuetify.js')

// create some user stories: https://www.educowebdesign.com/blog/insights/agile-development-guide
// As a [type of user], I want [some feature] so that I can [have some benefit, achieve some goal]

// speeding up https://github.com/vuejsdevelopers/blog/wiki/4-Ways-To-Boost-Your-Vue.js-App-With-Webpack

// critical css (if needed): https://vuejsdevelopers.com/2017/07/24/critical-css-webpack/

// Updating meta info: https://github.com/declandewet/vue-meta
// Prerendering (generating static files for serving) https://github.com/chrisvfritz/prerender-spa-plugin

// initial state from server (bottom): https://vuejsdevelopers.com/2017/08/28/vue-js-ajax-recipes/

// for persistant data
// https://github.com/robinvdvleuten/vuex-persistedstate
// https://github.com/championswimmer/vuex-persist
// https://github.com/superwf/vuex-cache

// https://github.com/f/vuex-loading

/* eslint-disable no-new */
new Vue({
  el: '#app',
  store,
  router,
  template: '<App/>',
  components: { App }
})
