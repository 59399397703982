<template>
  <v-form v-model="valid" ref="form" novalidate @submit="submit">
    <v-container grid-list-md>
      <v-layout row wrap>
        <v-flex xs12>
          <p>If you would like to switch to a different subscription level or change any other info, please <router-link :to="{name: 'AccountContact'}">contact us</router-link>.</p>
          <p>If you wish to cancel your account, type your login information below and click "cancel account".</p>
          <p v-if="accounttype === 'monthly'">
            You should recieve a refund for the current month within 10 business days.
          </p>
          <p v-if="accounttype === 'annual'">
            You should recieve your pro-rated refund within 10 business days.
          </p>
        </v-flex>
        <v-flex xs12 sm6>
          <v-text-field
            label="Email"
            v-model="user.account"
            :rules="validate.loginEmailRules"
            required
          ></v-text-field>
        </v-flex>
        <v-flex xs12 sm6>
          <v-text-field
            label="Password"
            v-model="user.password"
            :rules="validate.loginPasswordRules"
            type="password"
            required
          ></v-text-field>
        </v-flex>
        <v-flex xs12>
          <v-btn color="red" class="white--text" type="submit">Cancel Account</v-btn>
          <br />Please note, <strong>this will cancel your subscription and remove your access to the site immediately.</strong>
        </v-flex>
      </v-layout>
    </v-container>
  </v-form>
</template>
<script>
import API from '@/api'
import { Validate } from '../../Shared'

export default {
  components: {
    Validate
  },

  data: () => ({
    user: {
      account: '',
      password: ''
    },
    valid: false,
    validate: Validate
  }),

  computed: {
    accounttype () {
      return this.$store.state.user.accounttype.toLowerCase()
    }
  },

  methods: {
    submit () {
      event.preventDefault()
      API.put('account/cancel', {
        data: { user: this.user },
        errorMessage: 'There was an error cancelling your account',
        rollbarMessage: 'Error attempting to cancel account',
        validateElem: this.$refs.form,
        success: data => {
          this.$router.push({
            name: 'Cancelled'
          })
        }
      }, this)
    }
  }
}
</script>