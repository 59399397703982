<template>
  <span>
    <nav-icon v-for="item in mainTopNav" :navItem="item" :key="item.title" :title="item.title" v-if="!accountArea"></nav-icon>
    <nav-icon v-for="item in accountTopNav" :navItem="item" :key="item.title" :title="item.title" v-if="accountArea"></nav-icon>
    <v-btn v-if="!accountArea" dark exact icon @click="" title="Log In" :to="{ name: 'LogIn' }">
      <v-icon>fa-sign-in-alt</v-icon>
    </v-btn>
    <v-menu offset-y v-if="accountArea">
      <v-btn dark exact icon slot="activator">
        <v-icon>fa-user</v-icon>
      </v-btn>
      <v-list>
        <v-list-tile>
          <v-list-tile-title>{{ user.firstname }} {{ user.lastname }}</v-list-tile-title>
        </v-list-tile>
        <v-list-tile :to="{ name: 'Settings' }" title="Settings">
          <v-list-tile-avatar>
            <v-icon>fa-cog</v-icon>
          </v-list-tile-avatar>
          <v-list-tile-title>Settings</v-list-tile-title>
        </v-list-tile>
        <v-list-tile :to="{ name: 'AccountContact' }" title="Contact Us">
          <v-list-tile-avatar>
            <v-icon>fa-envelope</v-icon>
          </v-list-tile-avatar>
          <v-list-tile-title>Contact Us</v-list-tile-title>
        </v-list-tile>
        <v-list-tile @click="LogOut" title="Log Out">
          <v-list-tile-avatar>
            <v-icon>fa-sign-out-alt</v-icon>
          </v-list-tile-avatar>
          <v-list-tile-title>Log Out</v-list-tile-title>
        </v-list-tile>
      </v-list>
    </v-menu>
  </span>
</template>

<script>
import Auth from '@/auth'
import NavIcon from '../Nav/NavIcon'

export default {
  components: { NavIcon },

  computed: {
    user () {
      return this.$store.state.user
    }
  },

  props: {
    accountArea: { type: Boolean, default: false }
  },

  data: () => ({
    accountTopNav: [
      { title: 'Word List', icon: 'fa-list-ul', route: { name: 'AllWordLists' } },
      { title: 'Exercises', icon: 'fa-child', route: { name: 'ExerciseListing' } }
    ],
    mainTopNav: [
      { title: 'Blog', icon: 'fa-newspaper', href: '/blog/' },
      { title: 'About', icon: 'fa-info-circle', routeName: 'About' },
      { title: 'Store', icon: 'fa-shopping-cart', href: '/blog/store/' }
    ]
  }),

  methods: {
    LogOut () {
      Auth.logOut(this)
    }
  }
}
</script>