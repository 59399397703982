<template>
  <div>
    <p>
    	<a href="https://www.braintreegateway.com/merchants/5j369rhcsh43g8rh/verified" target="_blank">
    	  <img align="right" src="//s3.amazonaws.com/braintree-badges/braintree-badge-wide-light.png" width="223px" height ="35px" border="0"/>
    	</a>
    </p>
    <div id="dropin-container"></div>
  </div>
</template>
<script>
import API from '@/api'
const braintree = require('braintree-web-drop-in')

export default {
  name: 'payment-method',

  props: {
    checkPayment: { type: Boolean, required: true }
  },

  data: () => ({
    instance: null
  }),

  created () {
    API.get('payment/token', {
      auth: false,
      rollbarMessage: 'Error getting payment token',
      rollbarLevel: 'critical',
      success: (data) => {
        if (data && data.paymentToken) {
          const vue = this
          braintree.create({
            authorization: data.paymentToken,
            container: '#dropin-container',
            paypal: {
              method: 'flow'
            }
          },
          (createErr, instance) => {
            if (createErr) {
              // An error in the create call is likely due to
              // incorrect configuration values or network issues.
              // An appropriate error will be shown in the UI.
              vue.rollbar.critical('Error creating BrainTree drop in.', createErr)
            }

            vue.instance = instance
          })
        } else {
          this.rollbar.critical('Error getting payment token', data)
          this.$emit('paymentProcessorError')
        }
      },
      error: () => this.$emit('paymentProcessorError')
    }, this)
  },

  watch: {
    checkPayment (newValue) {
      if (newValue === true) {
        this.instance.requestPaymentMethod((requestPaymentMethodErr, payload) => {
          if (requestPaymentMethodErr) {
            // No payment method is available.
            // An appropriate error will be shown in the UI.
            this.rollbar.info('No BrainTree payment method available.', requestPaymentMethodErr)
            this.$emit('paymentError')
          } else if (payload === undefined || payload.nonce === '') {
            this.rollbar.warning('No BrainTree payment nonce created.', requestPaymentMethodErr)
            this.$emit('paymentError')
          } else {
            this.$emit('submitPayment', payload.nonce)
          }
        })

        this.$emit('resetPayment')
      }
    }
  }
}
// https://developers.braintreepayments.com/guides/drop-in/setup-and-integration/javascript/v3
// https://developers.braintreepayments.com/guides/drop-in/customization/javascript/v3
</script>
<style>
</style>