<template>
  <v-form v-model="valid" ref="form" novalidate @submit="update">
    <v-container grid-list-md>
      <v-layout row wrap>
        <v-flex xs12 md4>
          <v-text-field
            label="Current Password"
            v-model="password"
            :rules="validate.loginPasswordRules"
            type="password"
          ></v-text-field>
        </v-flex>
        <v-flex xs12 sm6 md4>
          <v-text-field
            append-icon="fa-lock"
            data-lpignore="true"
            label="New Password"
            ref="password"
            v-model="newPassword"
            :append-icon="hidePassword ? 'fa-eye' : 'fa-eye-slash'"
            :error-messages="passwordErrors"
            :rules="validate.passwordRules"
            :type="hidePassword ? 'password' : 'text'"
            @click:append="() => (hidePassword = !hidePassword)"
            @focus="clearPasswordErrors"
            counter
          ></v-text-field>
        </v-flex>
        <v-flex xs12 sm6 md4>
          <v-text-field
            append-icon="fa-lock"
            data-lpignore="true"
            label="Confirm Password"
            v-model="confirmPassword"
            :append-icon="hidePassword ? 'fa-eye' : 'fa-eye-slash'"
            :error-messages="passwordErrors"
            :rules="validate.passwordRules"
            :type="hidePassword ? 'password' : 'text'"
            @blur="passwordValidation"
            @click:append="() => (hidePassword = !hidePassword)"
            @focus="clearPasswordErrors"
            counter
          ></v-text-field>
        </v-flex>
        <v-flex class="text-xs-right" xs12>
          <v-btn color="primary" type="submit">Update</v-btn>
        </v-flex>
      </v-layout>
    </v-container>
  </v-form>
</template>
<script>
// https://github.com/algolia/vue-instantsearch algolia search component
import API from '@/api'
import { Validate } from '../../Shared'

export default {
  components: {
    Validate
  },

  data: () => ({
    accountErrors: [],
    confirmPassword: '',
    hidePassword: true,
    newPassword: '',
    password: '',
    passwordErrors: [],
    passwordValid: false,
    valid: false,
    validate: Validate
  }),

  methods: {
    clearPasswordErrors () {
      if (this.$refs.password.hasError) {
        this.passwordErrors = []
      }
    },

    passwordValidation () {
      if (this.$refs.password.validate()) {
        if (this.newPassword !== this.confirmPassword) {
          this.passwordErrors = [ 'Your password and confirmation password must match.' ]
        } else {
          this.clearPasswordErrors()
          return true
        }
      }
      return false
    },

    update (e) {
      e.preventDefault()
      if (this.passwordValidation()) {
        API.put('account/password', {
          data: { password: this.password, newPassword: this.newPassword },
          errorMessage: 'There was an error updating your password',
          rollbarMessage: 'Error attempting to update password',
          validateElem: this.$refs.form,
          success: data => {
            this.$store.commit('SET_TOAST_MESSAGE', { message: 'Your password has been updated.', color: 'success' })
          }
        }, this)
      }
    }
  }
}
</script>