<template>
  <v-container fluid grid-list-md>
    <v-layout class="mb-2" row wrap>
      <dashboard-list-item v-for="(listItem, index) in listItems" :key="listItem.id" :index="index" :listItem="listItem" :listType="listType"></dashboard-list-item>
    </v-layout>
  </v-container>
</template>

<script>
import DashboardListItem from './DashboardListItem/DashboardListItem'

export default {
  name: 'dashboard-list',
  props: {
    listItems: { type: Array, required: true },
    listType: { type: String, required: true }
  },
  components: {
    DashboardListItem
  },
  data: () => ({

  })
}
</script>