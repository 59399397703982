<template>
  <v-container grid-list-md>
    <h1 class="mb-2 headline" v-if="!child">Your Albums</h1>
    <v-data-table hide-headers hide-actions :items="albums">
      <template slot="no-data">
        <td v-if="dataLoaded"><v-btn color="primary" :to="{ name: 'CreateAlbum' }"><v-icon left>fa-plus</v-icon>Create Your First Album</v-btn></td>
        <td v-if="!dataLoaded">Loading albums...</td>
      </template>
      <template slot="items" slot-scope="props">
        <tr :key="props.item.ID">
          <td>
            <router-link :to="{ name: 'EditAlbum', params: { id: props.item.ID } }">
              {{ props.item.Album }}
            </router-link><br />
            <span class="nl2br">{{ props.item.Motivation }}</span>
          </td>
          <td><v-progress-circular
              :size="35"
              :width="4"
              :value="props.item.PercentComplete"
              :rotate="-90"
              color="secondary"
            >
              {{ props.item.PercentComplete }}
            </v-progress-circular>
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import API from '@/api'

export default {
  props: {
    child: { type: Boolean, default: false },
    dataSent: { type: Boolean, default: false },
    data: { type: Array, default: () => [] }
  },

  data () {
    return {
      dataLoaded: this.dataSent,
      albums: this.data
    }
  },

  head: {
    title: function () {
      if (!this.child) {
        return { inner: 'Album List' }
      }
    }
  },

  created () {
    if (!this.child) {
      API.get('albums/all', {
        errorMessage: 'There was an error getting your albums.',
        rollbarMessage: 'Error getting albums',
        success: data => {
          this.dataLoaded = true
          if (data !== undefined && data.Albums !== undefined) {
            this.albums = data.Albums
          }
        }
      }, this)
    }
  }
}
</script>