<template>
  <div>
    <v-layout row>
      <v-flex xs12>
        <v-form ref="form" novalidate v-model="valid" @submit="submit">
          <fieldset>
            <legend>Album Information</legend>
            <v-container grid-list-md>
              <v-layout row wrap>
                <v-flex xs12 sm8>
                  <v-text-field
                    data-lpignore="true"
                    maxlength="300"
                    label="Album Name"
                    v-model="album.album"
                    :rules="validate.albumNameRules"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm4>
                  <v-menu
                    ref="menu"
                    lazy
                    :close-on-content-click="false"
                    v-model="menu"
                    transition="scale-transition"
                    offset-y
                    full-width
                    :nudge-right="40"
                    min-width="290px"
                    :return-value.sync="album.duedate"
                  >
                    <v-text-field
                      clearable
                      slot="activator"
                      label="Due Date"
                      v-model="album.duedate"
                      prepend-icon="fa-calendar-alt"
                      readonly
                    ></v-text-field>
                    <v-date-picker 
                      v-model="album.duedate"
                      @input="$refs.menu.save(album.duedate)"></v-date-picker>

                  </v-menu>
                </v-flex>
                <v-flex xs12>
                  <v-card color="blue-grey lighten-3" class="white--text">
                    <v-card-title primary-title>
                      <div style="width:100%;">
                        <h3 class="headline mb-0">Motivation</h3>
                        <p>
                          <v-textarea
                            autofocus
                            auto-grow
                            counter="1000"
                            full-width
                            id="motivation"
                            maxlength="1000"
                            name="motivation"
                            placeholder="Add your motivation for writing this album."
                            v-model="album.motivation"
                            :rules="validate.motivationRules"
                          ></v-textarea>
                        </p>
                      </div>
                    </v-card-title>
                  </v-card>
                </v-flex>
                <v-flex xs12>
                  <p class="text-xs-right">
                    <v-btn color="primary" type="submit">Create</v-btn>    
                  </p>
                </v-flex>
              </v-layout>
            </v-container>
          </fieldset>
        </v-form>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import { Validate } from '../../Shared'
import API from '@/api'

export default {
  components: {
    Validate
  },

  data: () => ({
    album: {
      album: '',
      duedate: '',
      motivation: ''
    },
    menu: false,
    valid: false,
    validate: Validate
  }),

  head: {
    title: {
      inner: 'Create Your Album'
    }
  },

  methods: {
    submit (e) {
      e.preventDefault()
      API.post('album/create', {
        data: { album: this.album },
        errorMessage: 'There was an error creating your album.',
        rollbarMessage: 'Error creating album',
        validateElem: this.$refs.form,
        success: data => {
          this.$router.push({
            name: 'EditAlbum',
            params: {
              id: data.ID
            }
          })
        }
      }, this)
    }
  }
}
</script>