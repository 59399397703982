<template>
  <div>
    <v-layout row wrap>
      <v-flex xs-12>
        <h1 class="mb-2 display-2">Email Not Verified</h1>
      </v-flex>
    </v-layout>
    <v-layout row>
      <v-flex xs12>
        <p>Your new email verification failed. Please try clicking the link in your email again. If you continue having trouble you can contact us at <a @click="emailto" v-html="email"></a>.</p>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
export default {
  data: () => ({
    email: 'support&#64;lyricwell&#46;com',
    mailto: 'support@lyricwell.com'
  }),
  methods: {
    emailto () {
      event.preventDefault()
      window.open('mailto:' + this.mailto + '?subject=Email Verification Issues', '_self')
    }
  },
  head: {
    title: {
      inner: 'Email Not Verified'
    }
  }
}
</script>