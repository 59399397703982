<template>
  <v-stepper v-model="step" vertical>
    <!--https://codepen.io/josdea/pen/EXeopN-->
    <v-stepper-step :complete="step > 1" step="1">
      Exercise Summary
    </v-stepper-step>
    <v-stepper-content v-if="!imageOnly" step="1">
      <p>The most effective method for this exercise is using a pen or pencil and paper, but you can use the text area on the screen to type if you prefer. Your goal is to keep your pen or cursor moving across the page from the time you start until the timer runs out. Don't worry about any errors, just keep writing.</p>
      <p>If you're not sure where to start, write about absolutely anything that you can see, hear, feel, smell, or taste right now. If you still can't think of what to write, just write random letters or patterns. It doesn't have to make sense or be something you can use later.</p>
      <p>If you want to try something more challenging, try <router-link :to="{ name: 'ExerciseImageOnlyFreeWriting' }">Five Minute Image-Only Free Writing</router-link>.</p>
      <v-btn flat :to="{name: 'ExerciseListing'}">Cancel</v-btn>
      <v-btn color="primary" @click="nextStep">Start Now</v-btn>
    </v-stepper-content>
    <v-stepper-content v-if="imageOnly" step="1">
      <p>As with the standard free writing exercise, the most effective method for this exercise is using a pen or pencil and paper, 
        but you can use the text area on the screen to type if you prefer. If you haven't already done the standard free writing exercise, 
        <router-link :to="{ name: 'ExerciseFreeWriting' }">read about it here</router-link>. This exercise is the same but a little more restrictive to force you 
        to focus more and challenge you. The difference with this exercise is that you can only write using imagery, 
        so you should write about anything you can hear, see, smell, taste or touch, nothing else.</p>
      <v-btn flat :to="{name: 'ExerciseListing'}">Cancel</v-btn>
      <v-btn color="primary" @click="nextStep">Start Now</v-btn>
    </v-stepper-content>
    
     <v-stepper-step :complete="step > 2" step="2">
        Write
      </v-stepper-step>
      <v-stepper-content step="2">
        <timer :start=startTimer :stop=stopTimer :time=5 @completed="nextStep"></timer>
        <v-textarea
            v-model="words"
            label="Write here or on your piece of paper"
            ref="freewriting"
          ></v-textarea>
          <v-btn color="primary" @click="nextStep">Finish Early</v-btn>
      </v-stepper-content>
    
    <v-stepper-step :complete="step > 3" step="3">
      Finish
    </v-stepper-step>
    <v-stepper-content step="3">
      <v-container fluid grid-list-md>
        <v-layout row wrap>
          <v-flex xs12>
            Here's what you typed. You can add it to an album or your notebook below.
          </v-flex>
          <v-flex xs12 sm6 lg4 xl3>
            {{ words }}
          </v-flex>
          <v-flex xs12>
            <v-form ref="form" novalidate @submit="submit">
              <v-flex xs12 sm6 md3><!-- @todo: use combobox (https://vuetifyjs.com/en/components/combobox) to allow new album creation from here -->
                <v-autocomplete
                  append-icon="fa fa-sort-down"
                  data-lpignore="true"
                  :items="albums"
                  v-model="song.albumid"
                  item-text="Album"
                  item-value="ID"
                  label="Album"
                  :rules="validate.albumRules"
                ></v-autocomplete>
              </v-flex>
              <v-flex xs12 md6>
                <v-text-field
                  data-lpignore="true"
                  label="Song Title"
                  v-model="song.song"
                  :rules="validate.songTitleRules"
                ></v-text-field>
              </v-flex>
              <v-flex xs12>
                <p class="text-xs-right">
                  <v-btn color="primary" type="submit">Create</v-btn>    
                </p>
              </v-flex>
            </v-form>
          </v-flex>
        </v-layout>
      </v-container>
    </v-stepper-content>
  </v-stepper>
</template>

<script>
import API from '@/api'
import Timer from './Timer'
import { Validate } from '../../Shared'

export default {
  components: {
    Timer
  },
  data: () => ({
    albums: [],
    imageOnly: false,
    song: {
      albumid: '',
      lyrics: '',
      song: ''
    },
    startTimer: false,
    stopTimer: false,
    step: 1,
    validate: Validate,
    words: ''
  }),
  beforeDestroy () {
    this.stopTimer = true
  },
  head: {
    title: function () {
      if (this.$route.name === 'ExerciseFreeWriting') {
        return { inner: 'Free Writing' }
      } else {
        return { inner: 'Image Only Free Writing' }
      }
    }
  },
  created () {
    this.init()
    API.get('song/create', {
      rollbarMessage: 'Error getting albums',
      success: data => {
        if (data !== undefined) {
          if (data.Albums.length > 0) {
            this.albums = data.Albums
          }
        }
      }
    }, this)
  },
  methods: {
    init () {
      if (this.$route.name === 'ExerciseFreeWriting') {
        this.imageOnly = false
      } else {
        this.imageOnly = true
      }
    },
    nextStep () {
      this.step++
      if (this.step === 2) {
        this.startTimer = true
        this.$refs.freewriting.focus()
      } else if (this.step === 3) {
        this.stopTimer = true
      }
    },
    submit (e) {
      e.preventDefault()
      this.song.lyrics = this.words.replace(/\n/g, '<br />')
      API.post('song/create/' + this.song.albumid, {
        data: { song: this.song },
        errorMessage: 'There was an error creating your song.',
        rollbarMessage: 'Error creating song',
        validateElem: this.$refs.form,
        success: data => {
          this.$router.push({
            name: 'EditSong',
            params: { id: data.ID }
          })
        }
      }, this)
    }
  },
  watch: {
    '$route' (to, from) {
      this.init()
    }
  }
}
</script>