<template>
  <div>
    <v-layout row wrap>
      <v-flex xs-12>
        <h1 class="mb-2 display-2">Log In</h1>
      </v-flex>
    </v-layout>
    <v-layout row>
      <v-flex xs12 sm6 md4 lg3 xl2>
        <v-form v-model="valid" ref="form" novalidate @submit="submit">
          <v-text-field
            label="Email"
            v-model="user.account"
            :rules="validate.loginEmailRules"
            required
          ></v-text-field>
          <v-text-field
            label="Password"
            v-model="user.password"
            :rules="validate.loginPasswordRules"
            type="password"
            required
          ></v-text-field>
          <v-btn color="primary" type="submit">Log In</v-btn>
          <router-link class="caption" :to="{ name: 'ResetPasswordRequest' }">Forgot Your Password?</router-link>
        </v-form>
      </v-flex>
      <v-flex xs1 sm6 md8 lg9 xl10></v-flex>
    </v-layout>
  </div>
</template>
<script>
import Auth from '@/auth'
import { Validate } from '../../Shared'

export default {
  data () {
    return {
      loggedOff: this.$route.params.LoggedOff,
      valid: false,
      user: {
        account: '',
        password: ''
      },
      redirect: this.$route.query.redirect,
      validate: Validate
    }
  },

  head: {
    title: {
      inner: 'Login to Your LyricWell Account'
    },
    meta: [
      { name: 'description', content: 'Login to your LyricWell account.' }
    ]
  },

  created () {
    if (this.$route.params.LoggedOff) {
      this.$store.commit('SET_TOAST_MESSAGE', { message: 'You have been logged off.', color: 'success' })
    }
  },

  methods: {
    submit (e) {
      e.preventDefault()
      Auth.logIn(this, this.user, this.redirect)
    }
  }
}
</script>