<template>
  <v-stepper v-model="step" vertical>
    <v-stepper-step :complete="step > 1" step="1">
      Summary
    </v-stepper-step>
    <v-stepper-content step="1">
      <p>
        If you're on <a href="https://twitter.com/">Twitter</a>, this will be a fun challenge to share with your fans. Originally, Twitter had a 140 character limit per tweet and we're sticking with that. The challenge is to write ten "Twitter songs," songs that are 140 characters or less so you'll have a Twitter album. Don't worry about song titles for now, you can add them to your album later, or you can post the lyrics and allow your fans to suggest song titles based on the lyrics!
      </p>
      <v-btn flat :to="{name: 'ExerciseListing'}">Cancel</v-btn>
      <v-btn color="primary" @click="nextSong()">Start Now</v-btn>
    </v-stepper-content>

    <template v-for="(song, index) in songs">
      <v-stepper-step :complete="step > index + 2" :step="index + 2">
        {{ song.song }}
      </v-stepper-step>
      <v-stepper-content :step="index + 2">
        <v-form ref="songlyrics" novalidate @submit="validateLyrics">
          <v-text-field
              counter="140"
              data-lpignore="true"
              hint="(Enter for Next Song)"
              maxlength="140"
              ref="songlyricsinput"
              v-model="songs[index].lyrics"
              :label="'Twitter Song ' + (index + 1)"
              :rules="validate.lyricRules"
            ></v-text-field>
          <v-btn color="primary" type="submit">Next Song</v-btn>
        </v-form>
      </v-stepper-content>
    </template>
    
    <v-stepper-step :complete="step > (songs.length + 2)" :step="songs.length + 2">
      Finish
    </v-stepper-step>
    <v-stepper-content :step="songs.length + 2">
      <v-container fluid grid-list-md>
        <v-layout row>
          <v-flex xs12>Here are all of your twitter songs. You can create a new twitter album or tweet your new lyrics below.</v-flex>
        </v-layout>
        <v-layout row>
          <v-flex xs12>
            <v-form ref="album" novalidate @submit="submitAlbum">
              <v-text-field
                    data-lpignore="true"
                    label="Enter the Album Name"
                    v-model="albumName"
                    :rules="validate.albumNameRules"
                  ></v-text-field>
              <v-btn color="primary" type="submit">Add Album</v-btn>
            </v-form>
          </v-flex>
        </v-layout>
        <v-layout row wrap>
          <template v-for="(song, index) in songs">
            <v-flex xs12 sm6 lg4 xl3>
              <v-card class="blue-grey lighten-5" :href="'http://twitter.com/intent/tweet?text=' + song.lyrics" target="_blank">
                <v-card-title primary-title>
                  <div class="headline">Tweet Song {{ index + 1 }} <v-icon>fab fa-twitter</v-icon></div>
                  <div>
                    <p>{{ song.lyrics }}</p>
                  </div>
                </v-card-title>
              </v-card>
            </v-flex>
          </template>
        </v-layout>
      </v-container>
    </v-stepper-content>
  </v-stepper>
</template>

<script>
import { Validate } from '../../Shared'
import API from '@/api'

export default {
  components: {
    Validate
  },
  data: () => ({
    step: 1,
    albumName: '',
    songs: [
      { song: 'Song 1', lyrics: '' },
      { song: 'Song 2', lyrics: '' },
      { song: 'Song 3', lyrics: '' },
      { song: 'Song 4', lyrics: '' },
      { song: 'Song 5', lyrics: '' },
      { song: 'Song 6', lyrics: '' },
      { song: 'Song 7', lyrics: '' },
      { song: 'Song 8', lyrics: '' },
      { song: 'Song 9', lyrics: '' },
      { song: 'Song 10', lyrics: '' }
    ],
    validate: Validate
  }),
  head: {
    title: {
      inner: 'Twitter Writing Exercise'
    }
  },
  methods: {
    nextSong () {
      this.step++
      if (this.$refs.songlyricsinput[this.step - 2] !== undefined) {
        this.$refs.songlyricsinput[this.step - 2].focus()
      }
    },
    submitAlbum (e) {
      e.preventDefault()
      API.post('album/createwithsongs', {
        data: { 'album': { 'album': this.albumName, 'songs': this.songs } },
        errorMessage: 'There was an creating your album.',
        rollbarMessage: 'Error creating twitter album',
        validateElem: this.$refs.album,
        success: data => {
          this.$router.push({
            name: 'EditAlbum',
            params: {
              id: data.ID
            }
          })
        }
      }, this)
    },
    validateLyrics (e) {
      if (e !== undefined) {
        e.preventDefault()
      }
      if (this.$refs.songlyrics[this.step - 2].validate()) {
        this.nextSong()
      }
    }
  }
}
</script>

<style scoped>
  .headline { width: 100%; }
  .headline i { float: right; }
</style>