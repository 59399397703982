// Route authorization https://router.vuejs.org/en/advanced/meta.html
// https://auth0.com/blog/build-an-app-with-vuejs/
// logging in: https://developer.okta.com/blog/2017/09/14/lazy-developers-guide-to-auth-with-vue
// https://github.com/typicode/fetchival/issues/2
// https://medium.com/@alexmngn/the-essential-boilerplate-to-authenticate-users-on-your-react-native-app-f7a8e0e04a42
import API from '@/api'
import GTM from '@/gtm'

const LOGIN_URL = 'accounts/login'
const SIGNUP_URL = 'accounts/'
const ACCOUNT_INIT_URL = 'account/init'

export default {
  vue: null,

  setToken (vue, data) {
    vue.$ls.set('token', data.token, data.timestamp * 1000 - Date.now())
  },

  setStore (vue, data, authenticated) {
    vue.$store.commit('SET_USER_AUTHENTICATED', authenticated)

    let userInfo = {}

    if (data !== undefined && Object.keys(data).length > 0) {
      userInfo = {
        'user': data.user
      }
    }

    vue.$store.commit('SET_USER_INFORMATION', userInfo)
  },

  // Send a request to the login URL and save the returned JWT
  logIn (vue, user, redirect) {
    API.post(LOGIN_URL, {
      auth: false,
      data: { user: user },
      errorMessage: 'There was an error signing you in. Please try agian later.',
      rollbarMessage: 'Error signing in',
      validateElem: vue.$refs.form,
      success: (data) => {
        this.setStore(vue, data)
        if (redirect) {
          vue.$router.push(redirect)
        } else {
          vue.$router.push({
            name: 'Dashboard'
          })
        }
      }
    }, vue)
  },

  signUp (vue, user, recaptchaToken) {
    API.post(SIGNUP_URL, {
      auth: false,
      data: { user: user, recaptchaToken: recaptchaToken },
      rollbarMessage: 'Error signing up',
      success: (data) => {
        this.setStore(vue, data)

        GTM.purchase(vue.user.subscriptionType, function () {
          vue.$router.push({
            name: 'Dashboard'
          })
        })
      }
    }, vue)
  },

  clearSession (vue) {
    vue.$ls.remove('token')
    this.setStore(vue, {}, false)
  },

  // To log out, we just need to remove the token
  logOut (vue) {
    this.clearSession(vue)
    vue.$router.push({ name: 'LogIn',
      params: { LoggedOff: true }
    })
  },

  checkAuth (vue) {
    let jwt = vue.$ls.get('token')
    if (jwt) {
      return true
    }

    return false
  },

  init (vue) {
    if (!vue.$store.state.user.authenticationInitialized) {
      vue.$store.commit('SET_AUTHENTICATION_INITIALIZED')
      if (this.checkAuth(vue)) {
        API.get(ACCOUNT_INIT_URL, {
          errorMessage: 'There was an error initializing your account.',
          rollbarMessage: 'Error initializing account',
          success: data => this.setStore(vue, data, true)
        }, vue)
      } else {
        this.setStore(vue, {}, false)
      }
    }
  },

  loggedIn (vue) {
    return vue.$store.state.user.authenticated
  },

  // The object to be passed as a header for authenticated requests
  getToken (vue) {
    return vue.$ls.get('token')
  }
}
