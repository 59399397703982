<template>
  <div>
    <v-layout row v-if="!dataLoaded">
      <v-flex xs12>
        <v-progress-linear :indeterminate="true"></v-progress-linear>
      </v-flex>
    </v-layout>
    <v-layout row v-if="!isSandbox && dataLoaded">
      <v-flex xs12>
        <v-form ref="form" novalidate v-model="valid" @submit="submit">
          <fieldset>
            <legend>Album Information - {{ album.album }}
              <delete
                :button=true
                icon="fa-times-circle"
                :id=$route.params.id
                type="album"
                friendlyName="album"
                v-on:deleted="onDeleted"
                ></delete>
            </legend>
            <v-container grid-list-md>
              <v-layout row wrap>
                <v-flex xs12 sm8>
                  <v-text-field
                    data-lpignore="true"
                    maxlength="300"
                    label="Album Name"
                    v-model="album.album"
                    :rules="validate.albumNameRules"
                    @keyup="isDirty = true"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm4>
                  <v-menu
                    ref="menu"
                    lazy
                    :close-on-content-click="false"
                    v-model="menu"
                    transition="scale-transition"
                    offset-y
                    full-width
                    :nudge-right="40"
                    min-width="290px"
                    :return-value.sync="album.duedate"
                  >
                    <v-text-field
                      clearable
                      @click:clear="() => (isDirty = true)"
                      slot="activator"
                      label="Due Date"
                      v-model="album.duedate"
                      prepend-icon="fa-calendar-alt"
                      readonly
                    ></v-text-field>
                    <v-date-picker 
                      v-model="album.duedate"
                      @input="$refs.menu.save(album.duedate); isDirty = true"></v-date-picker>

                  </v-menu>
                </v-flex>
                <v-flex xs12 @click="showMotivationInput = true">
                  <v-card color="blue-grey lighten-3" class="white--text" hover v-if="!showMotivationInput">
                    <v-card-title primary-title>
                      <div>
                        <h3 class="headline mb-0"><v-icon>fa-edit</v-icon> Motivation</h3>
                        <div class="nl2br" v-if="album.motivation!=''">{{ album.motivation }}</div>
                        <div v-if="album.motivation==''">
                          Add your motivation for writing this album.
                        </div>
                      </div>
                    </v-card-title>
                  </v-card>
                  <v-card color="blue-grey lighten-3" class="white--text" v-if="showMotivationInput">
                    <v-card-title primary-title>
                      <div style="width:100%;">
                        <h3 class="headline mb-0">Motivation</h3>
                        <p>
                          <v-textarea
                            autofocus
                            auto-grow
                            counter="1000"
                            full-width
                            id="motivation"
                            maxlength="1000"
                            name="motivation"
                            v-if="showMotivationInput"
                            v-model="album.motivation"
                            :rules="validate.motivationRules"
                            @keyup="isDirty = true"
                          ></v-textarea>
                        </p>
                      </div>
                    </v-card-title>
                  </v-card>
                </v-flex>
                <v-flex xs12 v-if="showNotes" @click="showNotesInput = true">
                  <v-card color="blue-grey lighten-5" hover v-if="!showNotesInput">
                    <v-card-title primary-title>
                      <div>
                        <h3 class="headline mb-0"><v-icon>fa-edit</v-icon> Notes</h3>
                        <div class="nl2br" v-if="album.notes!=''">{{ album.notes }}</div>
                        <div v-if="album.notes==''">
                          Add notes for this album.
                        </div>
                      </div>
                    </v-card-title>
                  </v-card>
                  <v-card color="blue-grey lighten-5" v-if="showNotesInput">
                    <v-card-title primary-title>
                      <div style="width:100%;">
                        <h3 class="headline mb-0">Notes</h3>
                        <p>
                          <v-textarea
                            autofocus
                            auto-grow
                            counter="1000"
                            full-width
                            id="notes"
                            maxlength="1000"
                            name="notes"
                            v-if="showNotes"
                            v-model="album.notes"
                            :rules="validate.noteRules"
                            @keyup="isDirty = true"
                          ></v-textarea>
                        </p>
                      </div>
                    </v-card-title>
                  </v-card>
                </v-flex>
                <v-flex xs6 pl-2 pt-3 v-if="!showNotes" @click="showNotes = true; showNotesInput = true">
                  <a><v-icon>fa-plus</v-icon> Add Notes</a>
                </v-flex>
                <v-flex xs6 v-if="!showNotes">
                  <p class="text-xs-right">
                    <v-btn color="primary" type="submit" :disabled="!valid || !isDirty">Update</v-btn>    
                  </p>
                </v-flex>
                <v-flex xs12 v-if="showNotes">
                  <p class="text-xs-right">
                    <v-btn color="primary" type="submit" :disabled="!valid || !isDirty">Update</v-btn>
                  </p>
                </v-flex>
              </v-layout>
            </v-container>
          </fieldset>
        </v-form>
      </v-flex>
    </v-layout>
    <v-layout row v-if="isSandbox && dataLoaded">
      <v-flex xs12>
        <v-form ref="form" novalidate v-model="valid" @submit="submit">
          <fieldset>
            <legend>Album Information - {{ album.album }}</legend>
            <v-container grid-list-md>
              <v-layout row wrap>
                <v-flex xs12 v-if="showNotes" @click="showNotesInput = true">
                  <v-card color="blue-grey lighten-5" hover v-if="!showNotesInput">
                    <v-card-title primary-title>
                      <div>
                        <h3 class="headline mb-0"><v-icon>fa-edit</v-icon> Notes</h3>
                        <div class="nl2br" v-if="album.notes!=''">{{ album.notes }}</div>
                        <div v-if="album.notes==''">
                          Add notes for this album.
                        </div>
                      </div>
                    </v-card-title>
                  </v-card>
                  <v-card color="blue-grey lighten-5" v-if="showNotesInput">
                    <v-card-title primary-title>
                      <div style="width:100%;">
                        <h3 class="headline mb-0">Notes</h3>
                        <p>
                          <v-textarea
                            autofocus
                            auto-grow
                            counter="1000"
                            full-width
                            id="notes"
                            maxlength="1000"
                            name="notes"
                            v-if="showNotes"
                            v-model="album.notes"
                            :rules="validate.noteRules"
                            @keyup="isDirty = true"
                          ></v-textarea>
                        </p>
                      </div>
                    </v-card-title>
                  </v-card>
                </v-flex>
                <v-flex xs6 pl-2 pt-3 v-if="!showNotes" @click="showNotes = true; showNotesInput = true">
                  <a><v-icon>fa-plus</v-icon> Add Notes</a>
                </v-flex>
                <v-flex xs6 v-if="!showNotes">
                  <p class="text-xs-right">
                    <v-btn color="primary" type="submit" :disabled="!valid || !isDirty">Update</v-btn>    
                  </p>
                </v-flex>
                <v-flex xs12 v-if="showNotes">
                  <p class="text-xs-right">
                    <v-btn color="primary" type="submit" :disabled="!valid || !isDirty">Update</v-btn>
                  </p>
                </v-flex>
              </v-layout>
            </v-container>
          </fieldset>
        </v-form>
      </v-flex>
    </v-layout>
    <v-layout row v-if='dataLoaded'>
      <v-flex xs12>
        <sortable-songs></sortable-songs>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import { Validate } from '../../Shared'
import API from '@/api'
import Delete from '../Shared/Delete'
import SortableSongs from '../Song/SortableSongs'

export default {
  components: {
    Delete,
    SortableSongs,
    Validate
  },

  data: () => ({
    album: {
      album: '',
      duedate: '',
      motivation: ''
    },
    dataLoaded: false,
    isDirty: false,
    isSandbox: false,
    newSongTitle: '',
    menu: false,
    showMotivationInput: false,
    showNotes: false,
    showNotesInput: false,
    valid: false,
    validate: Validate
  }),

  head: {
    title: {
      inner: 'Edit your Album'
    }
  },

  created () {
    API.get('album/edit/' + this.$route.params.id, {
      rollbarMessage: 'Error getting album data',
      success: data => {
        if (data !== undefined) {
          this.dataLoaded = true
          this.album.album = data.Album
          if (data.DueDate !== '' && data.DueDate !== null) {
            this.album.duedate = data.DueDate
          }
          this.album.motivation = data.Motivation
          if (data.Notes !== '' && data.Notes !== null) {
            this.album.notes = data.Notes
            this.showNotes = true
          }
          this.isSandbox = data.IsSandbox
        }
      }
    }, this)
  },

  methods: {
    onDeleted () {
      this.$router.push({
        name: 'Dashboard'
      })
    },
    submit (e) {
      e.preventDefault()
      API.post('album/edit/' + this.$route.params.id, {
        data: { album: this.album },
        errorMessage: 'There was an error editing your album.',
        rollbarMessage: 'Error editing album',
        validateElem: this.$refs.form,
        success: data => {
          this.isDirty = false
          this.showMotivationInput = false
          this.showNotesInput = false
          this.$store.commit('SET_TOAST_MESSAGE', { message: 'Your album has been updated.', color: 'success' })
        }
      }, this)
    }
  }
}
</script>