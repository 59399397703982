<template>
  <div>
    <section>
      <v-layout
        column
        wrap
        class="my-5"
        align-center
      >
        <v-flex xs12 sm4 class="my-3">
          <div class="text-xs-center">
            <h2 class="headline">Page Not Found</h2>
            <span class="subheading">
              The page you're trying to to access does not exist.<br />
              <router-link :to="{ name: 'Contact' }">Contact Us</router-link> if you're having issues.
            </span>
          </div>
        </v-flex>
      </v-layout>
    </section>
    <!--<newsletter-signup></newsletter-signup>-->
  </div>
</template>

<script>
// import { NewsletterSignup } from '../../Shared'

export default {
  /* components: {
    NewsletterSignup
  },
  */
  head: {
    title: {
      inner: '404 Page Not Found'
    },
    script: [
      { src: '//widget.manychat.com/358139491060767.js', async: true }
    ]
  }
}
</script>
<style scoped>
</style>