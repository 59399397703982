<template>
  <div>
    <v-layout row wrap>
      <v-flex xs-12>
        <h1 class="mb-2 display-2">Random Word Generator</h1>
      </v-flex>
    </v-layout>
    <v-layout row wrap>
      <v-flex xs12 sm6 md4 lg3 xl2>
        <v-select
          :items="numberOfWordOptions"
          v-model="numberOfWords"
          label="Number of Words"
        ></v-select>
      </v-flex>
      <v-flex xs12 sm6 md8 lg9 xl10 pl-4>
        <v-btn color="primary" @click="getWords">Genenerate Word(s)</v-btn>
      </v-flex>
      <v-flex xs12>
        <ol>
          <li v-for="word in words">{{ word.word }}</li>
        </ol>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import API from '@/api'

export default {
  data: () => ({
    numberOfWords: 1,
    numberOfWordOptions: [ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15 ],
    words: []
  }),
  created () {
    let words = parseInt(this.$route.query.words)
    if (words > 0 && words <= 15) {
      this.numberOfWords = words
      this.getWords()
    }
  },
  head: {
    title: {
      inner: 'Random Word Generator'
    },
    meta: [
      { name: 'description', content: 'Generate random words.' }
    ]
  },
  methods: {
    getWords () {
      if (this.numberOfWords > 0 && this.numberOfWords <= 15) {
        API.get('wordwizard/randomwords/' + this.numberOfWords, {
          rollbarMessage: 'Error getting words',
          success: data => {
            if (data !== undefined) {
              this.words = data.randomwords
              this.$refs.lyrics.focus()
            }
          }
        }, this)
      }
    }
  }
}
</script>