<template>
  <span>
    <span v-if="!copied"><v-icon class="ml-3" color="secondary" @click="copyToClipboard()">fa-copy</v-icon></span>
    <span v-else><small>copied</small></span>
  </span>
</template>

<script>
export default {
  props: {
    text: { type: String, required: true }
  },
  data () {
    return {
      copied: false
    }
  },
  methods: {
    copyToClipboard () {
      let textarea = document.createElement('textarea')
      textarea.textContent = this.text
      document.body.appendChild(textarea)

      var selection = document.getSelection()
      var range = document.createRange()
      range.selectNode(textarea)
      selection.removeAllRanges()
      selection.addRange(range)
      // range.selectNodeContents(elem)
      try {
        if (document.execCommand('copy')) {
          this.copied = true
          setTimeout(() => {
            this.copied = false
          }, 1200)
        } else {
          this.$store.commit('SET_TOAST_MESSAGE', { message: 'There was an error copying to the clipboard' })
        }
        document.body.removeChild(textarea)
        // selection.removeAllRanges()
      } catch (e) {
        this.$store.commit('SET_TOAST_MESSAGE', { message: 'There was an error copying to the clipboard' })
        this.rollbar.warning('Error copying to clipboard', e)
      }
    }
  }
}
</script>