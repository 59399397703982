<template>
  <div>
    <v-layout mb-5>
      <v-flex xs12>
        <v-layout row wrap>
          <v-flex xs12>
            <h1 class="mb-2 display-1">Welcome {{ user.firstname }}</h1>
          </v-flex>
          <!--<v-flex xs12 hidden-md-and-up>
            <v-alert outline color="info" :value="true">
              <a class="text-xs-right"><v-icon>fa-book</v-icon></a>
              Download the Break Your Creative Block eBook 
              (<a @click="downloadeBook('pdf')">pdf</a> | <a @click="downloadeBook('epub')">ePub</a>)
            </v-alert>
          </v-flex>-->
          <v-flex xs12 @click="showMotivationInput = true">
            <v-card color="blue-grey lighten-5" hover v-if="!showMotivationInput && initialized">
              <v-card-title primary-title>
                <div>
                  <h3 class="headline mb-0"><v-icon>fa-edit</v-icon> Motivation</h3>
                  <div class="nl2br" v-if="motivation!=''">{{ motivation }}</div>
                  <div v-if="motivation==''">
                    Everyone has times when they hit a wall and just don't feel like writing anymore. Whether it's the reason you started writing, an event in your life, or a quote, write something down to keep you motivated.
                  </div>
                </div>
              </v-card-title>
            </v-card>
            <v-card color="blue-grey lighten-5" v-if="showMotivationInput">
              <v-card-title primary-title>
                <div style="width:100%;">
                  <h3 class="headline mb-0">Motivation</h3>
                  <p>
                    <v-textarea
                      autofocus
                      auto-grow
                      counter="1000"
                      full-width
                      id="motivation"
                      maxlength="1000"
                      name="motivation"
                      ref="rMotivation"
                      v-if="showMotivationInput"
                      v-model="motivation"
                      :rules="validate.motivationRules"
                    ></v-textarea>
                    <v-btn @click="changeMotivation">Update</v-btn>
                  </p>
                </div>
              </v-card-title>
            </v-card>
          </v-flex>
          <v-flex xs12>
            <v-card color="blue-grey lighten-5" v-if="writingPrompt !== ''">
              <v-card-title primary-title>
                <strong>Lyric Writing Prompt ({{ writingPromptType }} 
                  <v-tooltip top>
                    <v-btn class="ma-0" flat icon slot="activator" small @click="promptHelp = true">
                      <v-icon small>fa-question-circle</v-icon>
                    </v-btn>
                    <span>Help with the {{ writingPromptType }} lyric writing prompt type</span>
                  </v-tooltip>):</strong>&nbsp;<span v-html="writingPrompt"></span>
                  <v-tooltip top>
                    <v-btn class="ml-3" color="secondary" flat icon slot="activator" small @click="incrementWritingPrompt()">
                      <v-icon small>fas fa-redo</v-icon>
                    </v-btn>
                    <span>Skip to the next lyric writing prompt</span>
                  </v-tooltip>
              </v-card-title>
            </v-card>
            <v-card color="blue-grey lighten-5" v-else>
              <v-card-title primary-title>
                <strong>Congratulations! Looks like you've completed all of our lyric writing prompts.</strong>
              </v-card-title>
            </v-card>
            <v-dialog
              v-model="promptHelp"
            >
              <v-card>
                <v-card-title class="headline">{{ writingPromptType }} Lyric Writing Prompt Help</v-card-title>
                <v-card-text v-if="writingPromptType === 'About You'">
                  These are various aspects about you and how you think.
                </v-card-text>
                <v-card-text v-if="writingPromptType === 'Anywhere Line'">
                  Use this line anywhere in the song.
                </v-card-text>
                <v-card-text v-if="writingPromptType === 'Ending Line'">
                  Use this line at or near the end of the song. The line may be incomplete. If it is, finish it first.
                </v-card-text>
                <v-card-text v-if="writingPromptType === 'Random'">
                  Just follow the directions. These are random.
                </v-card-text>
                <v-card-text v-if="writingPromptType === 'Starting Line'">
                  Use this line at or near the beginning of the song.
                </v-card-text>
                <v-card-text v-if="writingPromptType === 'Story'">
                  Use this to write a song about this story.
                </v-card-text>
                <v-card-text v-if="writingPromptType === 'Title'">
                  Start with the title and write a song. The title may be incomplete. If it is, finish it first.
                </v-card-text>
                <v-card-text v-if="writingPromptType === 'Words'">
                  Use each of these words in a song.
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-flex>
        </v-layout>
      </v-flex>
      <!--<v-flex md3 offset-md1 hidden-sm-and-down>
        <a @click="downloadeBook('pdf')"><img alt="Download the Break Your Creative Block eBook" src="static/img/BreakYourCreativeBlock.png" width="157" /></a><br />
          Download <a @click="downloadeBook('pdf')">pdf</a> | <a @click="downloadeBook('epub')">ePub</a>
        </v-alert>
      </v-flex>-->
    </v-layout>
    <v-layout>
      <v-flex xs12>
        <h2 class="title">Recent Words and Phrases <small class="caption">(<router-link :to="{ name: 'AllWordLists' }">View All</router-link>)</small></h2>
      </v-flex>
    </v-layout>
    <word-lists :child="true" :dataSent="true" :data="wordLists" v-if="initialized"></word-lists>
    <v-layout>
      <v-flex xs12>
        <h2 class="title">Recent Songs <small class="caption">(<router-link :to="{ name: 'AllSongs' }">View All</router-link>)</small></h2>
      </v-flex>
    </v-layout>
    <song-list :child="true" :data="songs" :dataSent="true" v-if="initialized"></song-list>
    <v-layout>
      <v-flex xs12>
        <h2 class="title">Recent Albums <small class="caption">(<router-link :to="{ name: 'AllAlbums' }">View All</router-link>/<router-link :to="{ name: 'EditAlbum', params: { id: notebook.ID } }" v-if="notebook.ID">Notebook</router-link>)</small></h2>
      </v-flex>
    </v-layout>
    <album-list :child="true" :data="albums" :dataSent="true" v-if="initialized"></album-list>
  </div>
</template>
<script>
// https://github.com/algolia/vue-instantsearch algolia search component
import AlbumList from '../Album/List'
import API from '@/api'
import DashboardList from '../DashboardList/DashboardList'
import SongList from '../Song/List'
import { Validate } from '../../Shared'
import WordLists from '../WordList/List'
// import _ from 'lodash'
export default {
  components: {
    AlbumList,
    DashboardList,
    SongList,
    Validate,
    WordLists
  },

  head: {
    title: {
      inner: 'Dashboard'
    }
  },

  created () {
    API.get('account/dashboard?date=' + this.currentFormattedDate(), {
      success: data => {
        this.initialized = true
        this.albums = data.albums
        this.motivation = data.motivation
        this.notebook = data.notebook
        this.songs = data.songs
        this.wordLists = data.wordLists
        if (data.writingprompt !== null) {
          this.writingPromptType = data.writingprompt.writingprompttype
          this.writingPrompt = data.writingprompt.writingprompt
        }
      },
      errorMessage: 'There was an error loading your data.',
      rollbarMessage: 'Error getting dashboard'
    }, this)
  },
  data: () => ({
    wordLists: [],
    songs: [],
    albums: [],
    initialized: false,
    download: '',
    motivation: '',
    notebook: [],
    promptHelp: false,
    showMotivationInput: false,
    wordList: '',
    valid: false,
    validate: Validate,
    writingPromptType: '',
    writingPrompt: ''
  }),

  computed: {
    user () {
      return this.$store.state.user
    }
  },

  methods: {
    addZero (number) {
      if (number < 10) {
        return '0' + number
      }

      return number
    },
    currentFormattedDate () {
      let now = new Date()
      let date = now.getFullYear() + '-' + this.addZero(now.getMonth() + 1) + '-' + this.addZero(now.getDate()) + ' ' + this.addZero(now.getHours()) + ':' + this.addZero(now.getMinutes()) + ':' + this.addZero(now.getSeconds())

      return date
    },
    changeMotivation () {
      API.post('account/motivation', {
        data: { motivation: this.motivation },
        success: data => {
          this.$store.commit('SET_TOAST_MESSAGE', { message: 'Your motivation has been updated.', color: 'success' })
          this.showMotivationInput = false
        },
        message: 'There was an error setting your motivation.',
        rollbarMessage: 'Error setting motivation',
        validateElem: this.$refs.rMotivation
      }, this)
    },
    incrementWritingPrompt () {
      API.post('writingprompt/increment', {
        data: { date: this.currentFormattedDate() },
        success: data => {
          if (data.WritingPrompt !== undefined) {
            this.writingPromptType = data.WritingPromptType
            this.writingPrompt = data.WritingPrompt
          } else {
            this.writingPrompt = ''
            this.writingPromptType = ''
          }
        },
        message: 'There was an error getting the next lyric writing prompt.',
        rollbarMessage: 'Error getting lyric writing prompt'
      }, this)
    },
    /*
    downloadeBook (filetype) {
      this.download = 'Break-Your-Creative-Block.' + filetype
      API.get('download/' + this.download, {
        errorMessage: 'There was an error downloading the eBook.',
        requestSettings: {
          headers: {
            Accept: 'application/' + filetype
          },
          responseAs: 'response'
        },
        rollbarMesssage: 'eBook download error',
        success: data => {
          data.blob().then(blob => {
            let link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = this.download
            link.click()
          })
        }
      }, this)
    }, */
    onWordlistAdded (wordList) {
      if (this.wordLists.length === 4) {
        this.wordLists.pop()
      }
      this.wordLists.unshift({ id: wordList.ID, title: wordList.DateCreated, text: wordList.WordList })
    }
  }
}
</script>
<style>
  #motivation { font-size: 14px; }
</style>