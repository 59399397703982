<template>
  <div>
    <v-layout row v-if="!dataLoaded">
      <v-flex xs12>
        <v-progress-linear :indeterminate="true"></v-progress-linear>
      </v-flex>
    </v-layout>
    <v-layout row v-if="dataLoaded">
      <v-flex xs12>
        <v-form ref="form" novalidate v-model="valid" @submit="submit">
          <fieldset>
            <legend>Song Information - {{ song.song }}
              <delete
                :button=true
                icon="fa-times-circle"
                :id=$route.params.id
                type="song"
                friendlyName="song"
                v-on:deleted="onDeleted"
                ></delete>
            </legend>
            <v-container grid-list-md>
              <v-layout row wrap>
                <v-flex xs12 md6>
                  <v-text-field
                    data-lpignore="true"
                    label="Song Title"
                    v-model="song.song"
                    :rules="validate.songTitleRules"
                    @keyup="isDirty = true"
                  ></v-text-field>
                </v-flex>
                  <v-flex xs12 sm6 md3>
                    <v-autocomplete
                      append-icon="fa fa-sort-down"
                      data-lpignore="true"
                      :items="albums"
                      v-model="song.albumid"
                      item-text="Album"
                      item-value="ID"
                      label="Album"
                      :rules="validate.albumRules"
                    ></v-autocomplete>
                  </v-flex>
                <v-flex xs12 sm6 md3>
                  <v-menu
                    ref="menu"
                    lazy
                    :close-on-content-click="false"
                    v-model="menu"
                    transition="scale-transition"
                    offset-y
                    full-width
                    :nudge-right="40"
                    min-width="290px"
                    :return-value.sync="song.duedate"
                  >
                    <v-text-field
                      clearable
                      @click:clear="() => (isDirty = true)"
                      slot="activator"
                      label="Due Date"
                      v-model="song.duedate"
                      prepend-icon="fa-calendar-alt"
                      readonly
                    ></v-text-field>
                    <v-date-picker 
                      v-model="song.duedate"
                      @input="$refs.menu.save(song.duedate); isDirty = true"></v-date-picker>

                  </v-menu>
                </v-flex>
                <v-flex xs12 @click="showMotivationInput = true">
                  <v-card color="blue-grey lighten-3" class="white--text" hover v-if="!showMotivationInput">
                    <v-card-title primary-title>
                      <div>
                        <h3 class="headline mb-0"><v-icon>fa-edit</v-icon> Motivation</h3>
                        <div class="nl2br" v-if="song.motivation!=''">{{ song.motivation }}</div>
                        <div v-if="song.motivation==''">
                          Add your motivation for writing this song.
                        </div>
                      </div>
                    </v-card-title>
                  </v-card>
                  <v-card color="blue-grey lighten-3" class="white--text" v-if="showMotivationInput">
                    <v-card-title primary-title>
                      <div style="width:100%;">
                        <h3 class="headline mb-0">Motivation</h3>
                        <p>
                          <v-textarea
                            autofocus
                            auto-grow
                            counter="1000"
                            full-width
                            id="motivation"
                            maxlength="1000"
                            name="motivation"
                            v-if="showMotivationInput"
                            v-model="song.motivation"
                            :rules="validate.motivationRules"
                            @keyup="isDirty = true"
                          ></v-textarea>
                        </p>
                      </div>
                    </v-card-title>
                  </v-card>
                </v-flex>
                <v-flex xs12 v-if="showNotes" @click="showNotesInput = true">
                  <v-card color="blue-grey lighten-5" hover v-if="!showNotesInput">
                    <v-card-title primary-title>
                      <div>
                        <h3 class="headline mb-0"><v-icon>fa-edit</v-icon> Notes</h3>
                        <div class="nl2br" v-if="song.notes!=''">{{ song.notes }}</div>
                        <div v-if="song.notes==''">
                          Add notes for this song.
                        </div>
                      </div>
                    </v-card-title>
                  </v-card>
                  <v-card color="blue-grey lighten-5" v-if="showNotesInput">
                    <v-card-title primary-title>
                      <div style="width:100%;">
                        <h3 class="headline mb-0">Notes</h3>
                        <p>
                          <v-textarea
                            autofocus
                            auto-grow
                            counter="1000"
                            full-width
                            id="notes"
                            maxlength="1000"
                            name="notes"
                            v-if="showNotes"
                            v-model="song.notes"
                            :rules="validate.noteRules"
                            @keyup="isDirty = true"
                          ></v-textarea>
                        </p>
                      </div>
                    </v-card-title>
                  </v-card>
                </v-flex>
                <v-flex xs6 pl-2 pt-3 v-if="!showNotes">
                  <a @click="showNotes = true; showNotesInput = true"><v-icon>fa-plus</v-icon> Add Notes</a>
                </v-flex>
                <v-flex xs6 v-if="!showNotes">
                  <p class="text-xs-right">
                    <v-btn color="primary" type="submit" :disabled="!valid || !isDirty">Update</v-btn>    
                  </p>
                </v-flex>
                <v-flex xs12 v-if="showNotes">
                  <p class="text-xs-right">
                    <v-btn color="primary" type="submit" :disabled="!valid || !isDirty">Update</v-btn>    
                  </p>
                </v-flex>
              </v-layout>
            </v-container>
          </fieldset>
          <fieldset>
            <legend>Lyrics</legend>
            <v-container grid-list-md>
              <v-layout row wrap>
                <v-flex xs12 ref="editorSection" :class="editorClass">
                  <v-card>
                    <v-card-title primary-title>
                      <div id="ql-toolbar">
                        <v-btn class="ql-bold" icon></v-btn>
                        <v-btn class="ql-italic" icon></v-btn>
                        <v-btn class="ql-underline" icon></v-btn>
                        <v-btn class="ql-strike" icon></v-btn>
                        <v-btn class="ql-search" icon></v-btn>
                      </div>
                      <v-toolbar dense flat>
                        <v-menu bottom left class="hidden-sm-and-up">
                          <v-btn slot="activator">
                            Format
                          </v-btn>
                          <v-list>
                            <v-list-tile @click="toggleFormat('bold')">
                              <v-list-tile-title><v-icon color="black">fa-bold</v-icon></v-list-tile-title>
                            </v-list-tile>
                            <v-list-tile @click="toggleFormat('italic')">
                              <v-list-tile-title><v-icon color="black">fa-italic</v-icon></v-list-tile-title>
                            </v-list-tile>
                            <v-list-tile @click="toggleFormat('underline')">
                              <v-list-tile-title><v-icon color="black">fa-underline</v-icon></v-list-tile-title>
                            </v-list-tile>
                            <v-list-tile @click="toggleFormat('strike')">
                              <v-list-tile-title><v-icon color="black">fa-strikethrough</v-icon></v-list-tile-title>
                            </v-list-tile>
                          </v-list>
                        </v-menu>
                        <v-tooltip class="hidden-xs-only" top>
                          <v-btn slot="activator" icon @click="toggleFormat('bold')"><v-icon>fa-bold</v-icon></v-btn>
                          <span>Bold</span>
                        </v-tooltip>
                        <v-tooltip class="hidden-xs-only" top>
                          <v-btn slot="activator" icon @click="toggleFormat('italic')"><v-icon>fa-italic</v-icon></v-btn>
                          <span>Italic</span>
                        </v-tooltip>
                        <v-tooltip class="hidden-xs-only" top>
                          <v-btn slot="activator" icon @click="toggleFormat('underline')"><v-icon>fa-underline</v-icon></v-btn>
                          <span>Underline</span>
                        </v-tooltip>
                        <v-tooltip class="hidden-xs-only" top>
                          <v-btn slot="activator" icon @click="toggleFormat('strike')"><v-icon>fa-strikethrough</v-icon></v-btn>
                          <span>Strikethrough</span>
                        </v-tooltip>
                        <v-spacer></v-spacer>
                        <v-tooltip top>
                          <v-btn slot="activator" icon @click="editorSearch()"><v-icon>fa-search</v-icon></v-btn>
                          <span>Word Search</span>
                        </v-tooltip>
                        <!-- <v-tooltip top>
                          <v-btn slot="activator" icon @click="printLyrics = true"><v-icon>fa-print</v-icon></v-btn>
                          <span>Print</span>
                        </v-tooltip> -->
                        <!-- @todo: https://teamtreehouse.com/library/flip-transition see if I can use transitions -->
                        <v-icon ref="saveIcon"
                                v-bind:class="{ 'error--text': lyricsDirty, 'success--text': saving, 'success--text--fade': saveSuccess }">fa-save</v-icon>
                        <v-tooltip top v-if="fullScreenEnabled">
                          <v-btn slot="activator" icon @click="toggleFullScreen()"><v-icon>fa-expand</v-icon></v-btn>
                          <span>Fullscreen</span>
                        </v-tooltip>
                        <v-tooltip top>
                          <v-btn slot="activator" icon @click="toggleComplete()"><v-icon :color="(song.datecompleted === null ? 'secondary' : 'red')">fa-check</v-icon></v-btn>
                          <span v-if="song.datecompleted === null">Mark Song Complete</span>
                          <span v-if="song.datecompleted !== null">Mark Song Incomplete</span>
                        </v-tooltip>
                      </v-toolbar>
                    </v-card-title>
                    <v-card-text>
                      <div class="editor" ref="editor"></div><!-- @todo: need to figure out validation -->
                    </v-card-text>
                    <v-card-actions>
                      <v-layout row wrap>
                        <v-flex xs12><v-divider></v-divider></v-flex>
                        <v-flex xs12>
                          <v-text-field
                            data-lpignore="true"
                            :append-icon="'fa-search'"
                            v-on:keyup.enter="wordWizard"
                            @keydown.native.enter="$event.preventDefault()"
                            hint="(Enter to Search)"
                            label="Word Lookup"
                            v-model="word"
                            @click:append="() => (wordWizard())"></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm6 class="word-lookup" v-if="rhymes.length > 0">
                          <v-list subheader>
                            <v-subheader>Rhymes <sup>1</sup></v-subheader>
                            <v-list-tile v-for="(rhyme, index) in rhymes" :key="index">
                              <v-tooltip top>
                                <v-btn slot="activator" icon @click="word = rhyme; wordWizard()"><v-icon>fa-book</v-icon></v-btn>
                                <span>Look Up {{ rhyme }}</span>
                              </v-tooltip>
                              <v-tooltip top>
                                <v-btn slot="activator" icon @click="editorInsert(rhyme)"><v-icon>fa-paste</v-icon></v-btn>
                                <span>Insert {{ rhyme }} in your lyrics</span>
                              </v-tooltip>
                              {{ rhyme }}
                            </v-list-tile>
                          </v-list>
                        </v-flex>
                        <v-flex xs12 sm6 class="word-lookup" v-if="Object.keys(thesaurus).length > 0">
                          <v-list subheader>
                            <v-subheader>Thesaurus <sup>2</sup></v-subheader>
                          </v-list>
                          <v-list subheader v-for="(words, meaning) in thesaurus" :key="meaning">
                            <v-subheader class="caption">{{ meaning.substr(meaning.indexOf(' ') + 1) }}</v-subheader>
                            <v-list-tile v-for="(tWord, index) in words" :key="index">
                              <v-tooltip top>
                                <v-btn slot="activator" icon @click="editorInsert(tWord)"><v-icon>fa-paste</v-icon></v-btn>
                                <span>Insert {{ tWord }} in your lyrics</span>
                              </v-tooltip>
                              <v-tooltip top>
                                <v-btn slot="activator" icon @click="word = tWord; wordWizard()"><v-icon>fa-book</v-icon></v-btn>
                                <span>Look Up {{ tWord }}</span>
                              </v-tooltip>
                              {{ tWord }}
                            </v-list-tile>
                          </v-list>
                        </v-flex>
                      </v-layout>
                    </v-card-actions>
                  </v-card>
                </v-flex>
                <v-flex xs12>
                  <small>1. Powered by <a href="http://rhymebrain.com/" title="Rhyme results are provided by RhymeBrain.com" target="_blank">RhymeBrain.com</a></small><br />
                  <small>2. Powered by <a href="http://www.dictionaryapi.com/" target="_blank">dictionaryapi.com</a></small>
                </v-flex>
              </v-layout>
            </v-container>
          </fieldset>
        </v-form>
      </v-flex>
    </v-layout>
    <!-- <print-lyrics v-if="printLyrics" :song=song :print=printLyrics firstName="nic" lastName="lubbers"></print-lyrics> -->
  </div>
</template>
<script>
// https://github.com/quilljs/quill
// @todo: print, autolink
// may switch to these for collaboration: https://github.com/ether/etherpad-lite, http://prosemirror.net/
// https://github.com/rafrex/fscreen for fullscreen

import { Validate } from '../../Shared'
import API from '@/api'
import Delete from '../Shared/Delete'
import Editor from 'quill'
import Fscreen from 'fscreen'
// import PrintLyrics from './PrintLyrics'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.bubble.css'

export default {
  components: {
    // PrintLyrics,
    Delete,
    Validate
  },

  data: () => ({
    albums: [],
    song: {
      albumid: '',
      song: '',
      lyrics: '',
      notes: '',
      duedate: '',
      motivation: '',
      oldalbumid: false,
      datecompleted: null
    },
    dataLoaded: false,
    editor: false,
    editorClass: '',
    fullScreenEnabled: false,
    menu: false,
    oldalbumid: false,
    showMotivationInput: false,
    showNotes: false,
    showNotesInput: false,
    valid: false,
    validate: Validate,
    word: '',
    rhymes: [],
    thesaurus: [],
    insertIndex: 0,
    lyricsDirty: false,
    isDirty: false,
    saving: false,
    saveSuccess: false
    // printLyrics: false
  }),

  head: {
    title: {
      inner: 'Edit Your Song'
    }
  },

  created () {
    API.get('song/edit/' + this.$route.params.id, {
      rollbarMessage: 'Error getting song data',
      success: data => {
        if (data !== undefined) {
          this.dataLoaded = true
          this.song.song = data.Song
          this.song.albumid = data.AlbumID
          this.oldalbumid = data.AlbumID
          this.song.datecompleted = data.DateCompleted
          if (data.DueDate !== '' && data.DueDate !== null) {
            this.song.duedate = data.DueDate
          }
          /*
          if (data.DateCompleted !== '' && data.DateCompleted !== null) {
            this.song.datecompleted = data.DateCompleted
          }
          */
          this.song.motivation = data.Motivation
          if (data.Notes !== '' && data.Notes !== null) {
            this.song.notes = data.Notes
            this.showNotes = true
          }

          if (data.Albums.length > 0) {
            this.albums = data.Albums
          }
          this.$nextTick(() => {
            if (data.Lyrics !== undefined && data.Lyrics !== null) {
              if (data.Lyrics.ops !== undefined) {
                this.createEditor()
                this.editor.setContents(data.Lyrics.ops, 'silent')
              } else {
                this.$refs.editor.innerHTML = data.Lyrics
                this.createEditor()
              }
            } else {
              this.createEditor()
            }
            this.song.lyrics = this.editor.getContents()
          })
        }
      }
    }, this)

    this.fullScreenEnabled = Fscreen.fullscreenEnabled
  },

  methods: {
    createEditor () {
      let vue = this
      let icons = Editor.import('ui/icons')
      icons['bold'] = '<i class="fa fa-bold fa-quillicon" aria-hidden="true"></i>'
      icons['italic'] = '<i class="fa fa-italic fa-quillicon" aria-hidden="true"></i>'
      icons['underline'] = '<i class="fa fa-underline fa-quillicon" aria-hidden="true"></i>'
      icons['strike'] = '<i class="fa fa-strikethrough fa-quillicon" aria-hidden="true"></i>'
      icons['search'] = '<i class="fa fa-search fa-quillicon" aria-hidden="true"></i>'

      /* eslint-disable no-new */
      this.editor = new Editor('.editor', {
        modules: {
          toolbar: {
            container: '#ql-toolbar',
            formats: [ 'bold', 'italic', 'underline', 'strike', 'search' ],
            handlers: {
              'search': () => {
                this.editorSearch()
              }
            }
          }
        },
        placeholder: 'Type lyrics here',
        theme: 'bubble'
      })

      let timer = null
      this.editor.on('text-change', function () {
        vue.lyricsDirty = true
        vue.fadeSuccess = false
        vue.song.lyrics = vue.editor.getContents()
        if (timer !== null) {
          window.clearTimeout(timer)
        }
        timer = window.setTimeout(function () {
          timer = null
          vue.saving = true
          vue.saveLyrics()
        }, 1000)
      })
    },
    onDeleted () {
      this.$router.push({
        name: 'EditAlbum',
        params: { id: this.song.albumid }
      })
    },
    wordWizard () {
      if (this.word !== '') {
        this.rhymes = []
        this.thesaurus = []
        API.post('wordwizard/words', {
          data: { word: this.word },
          errorMessage: 'There was an error finding information for the word ' + this.word,
          rollbarMessage: 'Error getting info for the word ' + this.word,
          success: data => {
            if (data !== undefined) {
              if (data.rhymes !== undefined) {
                this.rhymes = data.rhymes
              } else {
                this.rhymes = []
              }
              if (data.thesaurus !== undefined) {
                this.thesaurus = data.thesaurus
              } else {
                this.thesaurus = []
              }

              // synonym (wordnik)
              /* if(returnData.synonym!==undefined){
                $.each(returnData.synonym, function(tKey, tValue){
                  if(tValue!=''){
                    var word=$('<li></li>').html(tValue);
                  }
                });
                if($('#ww_synonym').attr('id')!==undefined){
                  wordlist.appendTo('#ww_synonym');
                }
              }
              else{
                $('#ww_synonym').html('No Results.');
              } */
            }
          },
          error: errorData => {
            this.rhymes = []
            this.thesaurus = []
          }
        }, this)
      } else {
        this.$store.commit('SET_TOAST_MESSAGE', { message: 'Please enter a word to search.', color: 'warning' })
      }
    },
    nextIndexOf (fullString, regex, fromIndex) {
      let str = fromIndex ? fullString.substring(fromIndex) : fullString
      let match = str.match(regex)
      return match ? str.indexOf(match[0]) + fromIndex : -1
    },
    prevIndexOf (fullString, regex, fromIndex) {
      let str = fromIndex ? fullString.substring(0, fromIndex) : fullString
      let match = str.match(regex)
      return match ? str.lastIndexOf(match[match.length - 1]) : -1
    },
    getEditorRange () {
      return this.editor.getSelection()
    },
    getCursorIndex () {
      let range = this.getEditorRange()
      if (range !== null) {
        this.insertIndex = this.nextIndexOf(this.editor.getText(), /\s/, range.index)
      }
      return this.insertIndex
    },
    editorSearch () {
      let range = this.getEditorRange()
      if (range !== null) {
        if (range.length === 0) {
          let text = this.editor.getText()
          let nextIndex = this.nextIndexOf(text, /\s/, range.index)
          let prevIndex = this.prevIndexOf(text, /\s/, range.index)

          if (prevIndex === -1 || prevIndex > nextIndex) {
            prevIndex = 0
          }

          this.word = this.editor.getText(prevIndex, nextIndex - prevIndex).trim()
          this.insertIndex = nextIndex
        } else {
          this.word = this.editor.getText(range.index, range.length)
        }

        this.wordWizard()
      } else {
        this.$store.commit('SET_TOAST_MESSAGE', { message: 'Your cursor is not in the editor, no word to search for.', color: 'warning' })
      }
    },
    editorInsert (word) {
      this.editor.insertText(this.getCursorIndex(), ' ' + word)
    },
    saveLyrics () {
      API.post('song/edit/' + this.$route.params.id, {
        data: { song: this.song },
        errorMessage: 'There was an error saving your lyrics.',
        rollbarMessage: 'Error editing song',
        success: json => {
          this.isDirty = false
          this.lyricsDirty = false
          this.saving = false
          this.saveSuccess = true
        },
        alert: alerts => {
          this.$store.commit('HANDLE_API_ALERTS', { Alerts: alerts })
          this.saving = false
        }
      }, this)
    },
    submit (e) {
      e.preventDefault()
      if (this.oldalbumid !== this.song.albumid) {
        this.song.oldalbumid = this.oldalbumid
      } else {
        this.song.oldalbumid = false
      }
      API.post('song/edit/' + this.$route.params.id, {
        data: { song: this.song },
        errorMessage: 'There was an error editing your song.',
        rollbarMessage: 'Error editing song',
        validateElem: this.$refs.form,
        success: data => {
          this.isDirty = false
          if (this.oldalbumid !== this.song.albumid) {
            this.oldalbumid = this.song.albumid
            this.song.oldalbumid = false
          }
          this.showMotivationInput = false
          this.showNotesInput = false
          this.$store.commit('SET_TOAST_MESSAGE', { message: 'Your song has been updated.', color: 'success' })
        }
      }, this)
    },
    toggleComplete () {
      let dateCompleted = new Date()
      API.post('song/togglecomplete/' + this.$route.params.id, {
        data: { datecompleted: (this.song.datecompleted !== null ? null : dateCompleted.getFullYear() + '-' + (dateCompleted.getMonth() + 1) + '-' + dateCompleted.getDate()) },
        errorMessage: 'There was an error marking your song complete.',
        rollbarMessage: 'Error editing song',
        success: data => {
          this.song.datecompleted = data.DateCompleted
        }
      }, this)
    },
    toggleFormat (format) {
      this.editor.format(format, !this.editor.getFormat().hasOwnProperty(format))
    },
    toggleFullScreen () {
      if (Fscreen.fullscreenElement !== null) {
        this.editorClass = ''
        Fscreen.exitFullscreen()
      } else {
        this.editorClass = 'fullscreen'
        Fscreen.requestFullscreen(this.$refs.editorSection)
      }
    }
  }
}
</script>
<style>
  .editor { width: 100%; }
  .fa-quillicon { color: #aaa; font-size: 15px; }
  .fullscreen { overflow-y: scroll; width: 100%; }
  .fullscreen .v-card { min-height:100%; }
  .ql-active > .fa-quillicon, .fa-quillicon:hover { color: white; }
  .ql-bubble.ql-toolbar button, .ql-bubble .ql-toolbar button { width: 20px; }
  .word-lookup { max-height: 35rem; overflow-y: scroll;  }
  @keyframes FadeOut {
    from {
      color: #4caf50;
    }

    to {
      color: rgba(0,0,0,.54);
    }
  }

  .success--text--fade {
    --webkit-animation: FadeOut 2s ease-out;
    animation: FadeOut 3s ease-out;
  }
</style>