<template>
  <v-stepper v-model="step" vertical>
    <v-stepper-step :complete="step > 1" step="1">
      Exercise Summary
    </v-stepper-step>
    <v-stepper-content step="1">
      <p>This exercise is broken into six sections ending with a finished song. The goal if this exercise is to change things up by giving yourself a 
        very short amount of time to write a song, in this case ten minutes or less. You can click next step at any point to skip to the next step. 
        You can review the steps below to get an idea of what you'll be doing.</p>
      <v-btn flat :to="{name: 'ExerciseListing'}">Cancel</v-btn>
      <v-btn color="primary" @click="nextStep">Start Now</v-btn>
    </v-stepper-content>
    
    <v-stepper-step :complete="step > 2" step="2">
     Choose a topic <small>(30 seconds)</small>
    </v-stepper-step>
    <v-stepper-content step="2">
      <timer :start=startTimer[0] :stop=stopTimer[0] :time=.5 @completed="nextStep"></timer>
      <v-text-field
          v-model="topic"
          label="Choose your topic"
          ref="topic"
        ></v-text-field>
        <v-btn color="primary" @click="nextStep">Next Step</v-btn>
    </v-stepper-content>
    
    <v-stepper-step :complete="step > 3" step="3">
     Create a list of words related to that topic <small>(1 minute)</small>
    </v-stepper-step>
    <v-stepper-content step="3">
      <timer :start=startTimer[1] :stop=stopTimer[1] :time=1 @completed="nextStep"></timer>
      <v-textarea
          v-model="relatedWords"
          :label="'Words related to your topic: ' + topic"
          ref="relatedWords"
        ></v-textarea>
        <v-btn color="primary" @click="nextStep">Next Step</v-btn>
    </v-stepper-content>
    
    <v-stepper-step :complete="step > 4" step="4">
     Choose a chord progression <small>(2 minutes)</small>
    </v-stepper-step>
    <v-stepper-content step="4">
      <timer :start=startTimer[2] :stop=stopTimer[2] :time=2 @completed="nextStep"></timer>
      <v-textarea
          v-model="chords"
          label="Choose a chord progression. Write the chords or any notes below."
          ref="chords"
        ></v-textarea>
        <v-btn color="primary" @click="nextStep">Next Step</v-btn>
    </v-stepper-content>
    
    <v-stepper-step :complete="step > 5" step="5">
     Write the lyrics <small>(3 minutes)</small>
    </v-stepper-step>
    <v-stepper-content step="5">
      <timer :start=startTimer[3] :stop=stopTimer[3] :time=3 @completed="nextStep"></timer>
      <span class="nl2br">Related Words: {{ relatedWords }}</span>
      <v-textarea
          v-model="song.lyrics"
          label="Write the lyrics. Use your related words to help."
          ref="lyrics"
        ></v-textarea>
        <v-btn color="primary" @click="nextStep">Next Step</v-btn>
    </v-stepper-content>
    
    <v-stepper-step :complete="step > 6" step="6">
     Create a melody <small>(2 minutes)</small>
    </v-stepper-step>
    <v-stepper-content step="6">
      <timer :start=startTimer[4] :stop=stopTimer[4] :time=2 @completed="nextStep"></timer>
      <span class="nl2br">Chords: {{ chords }}</span>
      <v-textarea
          v-model="melody"
          label="Create a melody using your chord progression."
          ref="melody"
        ></v-textarea>
        <v-btn color="primary" @click="nextStep">Next Step</v-btn>
    </v-stepper-content>

    <v-stepper-step :complete="step > 7" step="7">
     Make modifications <small>(1.5 minutes)</small>
    </v-stepper-step>
    <v-stepper-content step="7">
      <timer :start=startTimer[5] :stop=stopTimer[5] :time=1.5 @completed="nextStep"></timer>
      <v-textarea
          v-model="song.lyrics"
          label="Lyrics"
        ></v-textarea>
      <v-textarea
          v-model="chords"
          label="Chords"
        ></v-textarea>
      <v-textarea
          v-model="melody"
          label="Melody"
        ></v-textarea>
        <v-btn color="primary" @click="nextStep">Next Step</v-btn>
    </v-stepper-content>


    <v-stepper-step :complete="step > 8" step="8">
      Finish
    </v-stepper-step>
    <v-stepper-content step="8">
      <v-container fluid grid-list-md>
        <v-layout row wrap>
          <v-flex xs12>
            Here is everything from your finished song. You can add it to an album or your notebook below.
          </v-flex>
          <v-flex xs12>
            Topic: {{ topic }}<br />
            Related Words: <span class="nl2br">{{ relatedWords }}</span><br />
            Lyrics: <span class="nl2br">{{ song.lyrics }}</span><br />
            Chords: <span class="nl2br">{{ chords }}</span><br />
            Melody: <span class="nl2br">{{ melody }}</span><br />
            Completed In: {{ minutes }}:{{ padTime(seconds) }}
          </v-flex>
          <v-flex xs12>
            <v-form ref="form" novalidate @submit="submit">
              <v-flex xs12 sm6 md3><!-- @todo: use combobox (https://vuetifyjs.com/en/components/combobox) to allow new album creation from here -->
                <v-autocomplete
                  append-icon="fa fa-sort-down"
                  data-lpignore="true"
                  :items="albums"
                  v-model="song.albumid"
                  item-text="Album"
                  item-value="ID"
                  label="Album"
                  :rules="validate.albumRules"
                ></v-autocomplete>
              </v-flex>
              <v-flex xs12 md6>
                <v-text-field
                  data-lpignore="true"
                  label="Song Title"
                  v-model="song.song"
                  :rules="validate.songTitleRules"
                ></v-text-field>
              </v-flex>
              <v-flex xs12>
                <p class="text-xs-right">
                  <v-btn color="primary" type="submit">Create</v-btn>    
                </p>
              </v-flex>
            </v-form>
          </v-flex>
        </v-layout>
      </v-container>
    </v-stepper-content>
  </v-stepper>
</template>

<script>
// @todo: add recordings as an option
import API from '@/api'
import Timer from './Timer'
import { Validate } from '../../Shared'

export default {
  components: {
    Timer,
    Validate
  },
  data: () => ({
    albums: [],
    chords: '',
    melody: '',
    minutes: 0,
    relatedWords: '',
    song: {
      albumid: '',
      lyrics: '',
      notes: '',
      song: ''
    },
    seconds: 0,
    startTimer: [ false, false, false, false, false, false ],
    stopTimer: [ false, false, false, false, false, false ],
    step: 1,
    timer: null,
    topic: '',
    totalTime: 0,
    validate: Validate
  }),
  beforeDestroy () {
    this.stopTimer = [ true, true, true, true, true, true ]
    this.stopTime()
  },
  head: {
    title: {
      inner: 'Ten Minute Song Exercise'
    }
  },
  created () {
    API.get('song/create', {
      rollbarMessage: 'Error getting albums',
      success: data => {
        if (data !== undefined) {
          if (data.Albums.length > 0) {
            this.albums = data.Albums
          }
        }
      }
    }, this)
  },
  methods: {
    nextStep () {
      this.step++
      switch (this.step) {
        case 2:
          this.$refs.topic.focus()
          break
        case 3:
          this.$refs.relatedWords.focus()
          break
        case 4:
          this.$refs.chords.focus()
          break
        case 5:
          this.$refs.lyrics.focus()
          break
        case 6:
          this.$refs.melody.focus()
          break
      }
      if (this.step > 2) {
        this.stopTimer[this.step - 3] = true
      }
      if (this.step < 8) {
        this.startTimer[this.step - 2] = true
      }
      if (this.step === 2) {
        this.totalTime = 0
        this.startTime()
      }
      if (this.step === 8) {
        this.stopTime()
      }
    },
    startTime () {
      this.timer = setInterval(() => this.countup(), 1000)
    },
    stopTime () {
      clearInterval(this.timer)
      this.timer = null
      this.minutes = Math.floor(this.totalTime / 60)
      this.seconds = this.totalTime - (this.minutes * 60)
    },
    padTime (time) {
      return (time < 10 ? '0' : '') + time
    },
    countup () {
      this.totalTime++
    },
    submit (e) {
      e.preventDefault()
      this.song.notes = 'Chords: ' + this.chords + '\nMelody: ' + this.melody + '\nTopic: ' + this.topic + '\nRelated Words: ' + this.relatedWords + '\nCompleted In: ' + this.minutes + ':' + this.seconds
      API.post('song/create/' + this.song.albumid, {
        data: { song: this.song },
        errorMessage: 'There was an error creating your song.',
        rollbarMessage: 'Error creating song',
        validateElem: this.$refs.form,
        success: data => {
          this.$router.push({
            name: 'EditSong',
            params: { id: data.ID }
          })
        }
      }, this)
    }
  }
}
</script>

<style scoped>
  .headline { width: 100%; }
  .headline i { float: right; }
</style>