import Vue from 'vue'
import Router from 'vue-router'
import { About, Cancelled, Contact, EmailFailed, EmailVerified, FreeTrial, Home, LogIn, PageNotFound, PrivacyPolicy, RandomWords, ResetPassword, ResetPasswordRequest } from '@/components/Pages'
import { AccountContact, AlbumList, Dashboard, CreateAlbum, CreateSong, EditAlbum, EditSong, ExerciseFreeWriting, ExerciseListing, ExerciseTenMinuteSong, ExerciseTwitter, ExerciseWord, Settings, SongList, WordLists } from '@/components/Account'
import Auth from '@/auth'
import Rollbar from 'vue-rollbar' // https://rollbar.com/docs/notifier/rollbar.js/ (debug, info, warning, error, critical)

Vue.use(Rollbar, {
  accessToken: 'bfad6687452a4053b60f8801e52763c1',
  captureUncaught: true,
  payload: {
    environment: 'development'
  }
})

// look into this: https://github.com/vuejsdevelopers/blog/wiki/4-Ways-To-Boost-Your-Vue.js-App-With-Webpack
// const Home = resolve => require(['../components/Pages/Home/Home.vue'], resolve)
// grouped routes: https://stackoverflow.com/questions/37838173/vuejs-application-with-different-layouts-e-g-login-layout-page-laypit-signup

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home
    },
    {
      path: '/index.html', redirect: { name: 'Home' }
    },
    {
      path: '/about',
      name: 'About',
      component: About
    },
    {
      path: '/account-cancelled',
      name: 'Cancelled',
      component: Cancelled
    },
    {
      path: '/contact-us',
      name: 'Contact',
      component: Contact
    },
    {
      path: '/email-failed',
      name: 'EmailFailed',
      component: EmailFailed
    },
    {
      path: '/email-verified',
      name: 'EmailVerified',
      component: EmailVerified
    },
    {
      path: '/free-trial',
      name: 'FreeTrial',
      component: FreeTrial
    },
    {
      path: '/log-in',
      name: 'LogIn',
      component: LogIn
    },
    {
      path: '/privacy-policy',
      name: 'PrivacyPolicy',
      component: PrivacyPolicy
    },
    {
      path: '/random-word-generator',
      name: 'RandomWords',
      component: RandomWords
    },
    {
      path: '/reset-password',
      name: 'ResetPassword',
      component: ResetPassword
    },
    {
      path: '/reset-password-request',
      name: 'ResetPasswordRequest',
      component: ResetPasswordRequest
    },
    {
      path: '/login', redirect: { name: 'LogIn' }
    },
    {
      path: '/sign-up', redirect: { name: 'FreeTrial' }
    },
    {
      path: '/account',
      name: 'Dashboard',
      component: Dashboard,
      meta: { requiresAuth: true }
    },
    {
      path: '/account/contact-us',
      name: 'AccountContact',
      component: AccountContact,
      meta: { requiresAuth: true }
    },
    {
      path: '/albums',
      name: 'AllAlbums',
      component: AlbumList,
      meta: { requiresAuth: true }
    },
    {
      path: '/album/create',
      name: 'CreateAlbum',
      component: CreateAlbum,
      meta: { requiresAuth: true }
    },
    {
      path: '/album/edit/:id',
      name: 'EditAlbum',
      component: EditAlbum,
      meta: { requiresAuth: true }
    },
    {
      path: '/exercises',
      name: 'ExerciseListing',
      component: ExerciseListing,
      meta: { requiresAuth: true }
    },
    {
      path: '/exercise/twitter',
      name: 'ExerciseTwitter',
      component: ExerciseTwitter,
      meta: { requiresAuth: true }
    },
    {
      path: '/exercise/freewriting',
      name: 'ExerciseFreeWriting',
      component: ExerciseFreeWriting,
      meta: { requiresAuth: true }
    },
    {
      path: '/exercise/imagefreewriting',
      name: 'ExerciseImageOnlyFreeWriting',
      component: ExerciseFreeWriting,
      meta: { requiresAuth: true }
    },
    {
      path: '/exercise/doubleword',
      name: 'ExerciseDoubleWord',
      component: ExerciseWord,
      meta: { requiresAuth: true }
    },
    {
      path: '/exercise/singleword',
      name: 'ExerciseSingleWord',
      component: ExerciseWord,
      meta: { requiresAuth: true }
    },
    {
      path: '/exercise/tenword',
      name: 'ExerciseTenWord',
      component: ExerciseWord,
      meta: { requiresAuth: true }
    },
    {
      path: '/exercise/tenminutesong',
      name: 'ExerciseTenMinuteSong',
      component: ExerciseTenMinuteSong,
      meta: { requiresAuth: true }
    },
    {
      path: '/account/settings',
      name: 'Settings',
      component: Settings,
      meta: { requiresAuth: true }
    },
    {
      path: '/song/create',
      name: 'CreateSong',
      component: CreateSong,
      meta: { requiresAuth: true }
    },
    {
      path: '/songs',
      name: 'AllSongs',
      component: SongList,
      meta: { requiresAuth: true }
    },
    {
      path: '/song/edit/:id',
      name: 'EditSong',
      component: EditSong,
      meta: { requiresAuth: true }
    },
    {
      path: '/wordlists/all',
      name: 'AllWordLists',
      component: WordLists,
      meta: { requiresAuth: true }
    },
    {
      path: '/wordlist/create',
      name: 'CreateWordList',
      component: WordLists,
      meta: { requiresAuth: true }
    },
    { // may want to improve 404 handling in the future but works for now, just not the best for internal links.
      path: '*',
      component: PageNotFound
    }
  ],
  scrollBehavior (to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash
        // , offset: { x: 0, y: 10 }
      }
    } else {
      return { x: 0, y: 0 }
    }
  }
})

/*
const navHandler = (router, inAccount, to) => {
  if ((inAccount && router.app.$store.state.defaultNav) || (!inAccount && !router.app.$store.state.defaultNav)) {
    // console.log((to !== undefined ? to.path : ''), inAccount)
    router.app.$store.commit('SET_MAIN_NAV', inAccount)
  }
}
*/

router.beforeEach((to, from, next) => {
  let lastEcommerce = -1
  window.dataLayer.forEach(function (item, index) {
    if ('ecommerce' in item) {
      lastEcommerce = (item.ecommerce !== undefined ? index : -1)
    }
  })
  if (lastEcommerce > -1) {
    window.dataLayer.push({
      'ecommerce': undefined
    })
  }

  Auth.init(router.app) // @todo: see if there's a way to do this on init rather than on every router call

  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!Auth.checkAuth(router.app)) {
      next({
        name: 'LogIn',
        query: { redirect: to.fullPath }
      })
    }

    // navHandler(router, true)
    next()
  } else {
    if ((to.path === '/log-in' || to.path === '/free-trial') && Auth.checkAuth(router.app)) {
      next({
        name: 'Dashboard'
      })
    }
    // navHandler(router, false, to)
    next() // make sure to always call next()!
  }
})

router.afterEach((to, from) => {
  window.dataLayer.push({
    'event': 'VirtualPageview',
    'virtualPageURL': to.path,
    'virtualPageTitle': to.name.replace(/([A-Z])/g, ' $1').trim()
  })
})

export default router
