<template>
  <span>
    <v-icon 
      @click="del"
      color="red"
      small
      v-if="!button">
      {{ icon }}
    </v-icon>
    <v-tooltip top v-if="button">
      <v-btn slot="activator" icon @click="del"><v-icon color="red">{{ icon }}</v-icon></v-btn>
      <span>Delete {{ friendlyName }}</span>
    </v-tooltip>
  </span>
</template>
<script>
import API from '@/api'

export default {
  name: 'delete',
  props: {
    button: { type: Boolean, default: false },
    icon: { type: String, default: 'fa-trash-alt' },
    id: { type: String, required: true },
    type: { type: String, required: true },
    friendlyName: { type: String, required: true }
  },

  methods: {
    del () {
      API.get(this.type + '/delete/' + this.id, {
        success: data => {
          this.$emit('deleted', this.id)
          this.$store.commit('SET_TOAST_MESSAGE', {
            message: 'Your ' + this.friendlyName + ' has been deleted.',
            function: () => {
              return this.undo()
            },
            color: 'success' })
        },
        errorMessage: 'There was an error deleting your ' + this.friendlyName + '.',
        rollbarMessage: 'Error deleting ' + this.friendlyName
      }, this)
    },
    undo () {
      API.get(this.type + '/restore/' + this.id, {
        success: data => {
          this.$emit('restored', this.id)
          this.$store.commit('SET_TOAST_MESSAGE', {
            message: 'Your ' + this.friendlyName + ' has been restored.',
            color: 'success' })
        },
        errorMessage: 'There was an error restoring your ' + this.friendlyName + '.',
        rollbarMessage: 'Error restoring ' + this.friendlyName
      }, this)
    }
  }
}
</script>