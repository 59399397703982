<template>
  <v-form ref="songform" novalidate v-model="valid" @submit="submit">
    <fieldset>
      <legend>Songs</legend>
      <v-container grid-list-md>
        <v-data-table hide-actions ref="sortableTable" :headers="headers" :items="songs">
          <template slot="no-data">
            <td class="px-1" style="width: 0.1%"></td>
            <td v-if="dataLoaded">Add your first song for this album</td>
            <td v-if="!dataLoaded">Loading songs...</td>
          </template>
          <template slot="items" slot-scope="props">
            <tr class="sortableRow" :key="props.item.id">
              <td class="px-1" style="width: 0.1%">
                <v-btn icon class="sortHandle"><v-icon>fa-sort</v-icon></v-btn>
              </td>
              <td><router-link :to="{ name: 'EditSong', params: { id: props.item.id } }">{{ props.item.title }}</router-link></td>
              <td class="text-xs-center"><v-icon color="secondary" v-if="props.item.datecompleted !== null">fa-check</v-icon></td>
            </tr>
          </template>
          <template slot="footer" v-if="dataLoaded">
            <td></td>
            <td>
              <v-layout row wrap>
                <v-flex xs12>
                  <v-text-field
                    append-outer-icon="fa-plus"
                    data-lpignore="true"
                    hint="(Enter to add)"
                    label="Song Title"
                    ref="songTitleInput"
                    v-model="newSongTitle"
                    :rules="validate.songTitleRules"
                    @click:append-outer="submit()"
                    ></v-text-field>
                </v-flex>
              </v-layout>
            </td>
            <td></td>
          </template>
        </v-data-table>
      </v-container>
    </fieldset>
  </v-form>
</template>
<script>
// https://github.com/RubaXa/Sortable
// https://codepen.io/webifi/pen/qPgbRK?editors=1011
// http://rubaxa.github.io/Sortable/
import { Validate } from '../../Shared'
import API from '@/api'
import Sortable from 'sortablejs'

export default {
  name: 'sortable-songs',

  components: {
    Validate
  },

  data: () => ({
    dataLoaded: false,
    headers: [
      { text: 'Position', value: 'Song', sortable: false },
      { text: 'Song', value: 'Song', sortable: false },
      { text: 'Completed', value: 'Completed', sortable: false, width: '100px' }
    ],
    newSongTitle: '',
    songs: [
    ],
    valid: false,
    validate: Validate
  }),

  created () {
    API.get('songs/byalbum/' + this.$route.params.id, {
      errorMessage: 'There was an error getting the songs for this album.',
      rollbarMessage: 'Error getting album songs',
      success: data => {
        this.dataLoaded = true
        if (data !== undefined && data.Songs !== undefined) {
          data.Songs.forEach((item) => {
            this.songs.push({
              id: item.ID, title: item.Song, datecompleted: item.DateCompleted
            })
          })

          this.createSortable()
        }
      }
    }, this)
  },

  methods: {
    createSortable () {
      Sortable.create(
        this.$refs.sortableTable.$el.getElementsByTagName('tbody')[0],
        {
          draggable: '.sortableRow',
          handle: '.sortHandle',
          onEnd: this.dragReorder
        }
      )
    },
    reorder (oldIndex, newIndex) {
      const movedItem = this.songs.splice(oldIndex, 1)[0]
      this.songs.splice(newIndex, 0, movedItem)
    },
    dragReorder ({ oldIndex, newIndex }) {
      this.reorder(oldIndex, newIndex)
      API.post('songs/order/' + this.songs[newIndex].id, {
        data: { oldPosition: oldIndex, newPosition: newIndex },
        success: data => {
          if (data === undefined) {
            this.reorder(newIndex, oldIndex)
          }
        },
        error: errorData => {
          this.reorder(newIndex, oldIndex)
          this.$store.commit('SET_TOAST_MESSAGE', { message: 'There was an error reordering the songs.' })
          this.rollbar.warning('Error reordering album songs', errorData)
        }
      }, this)
    },
    submit (e) {
      if (e !== undefined) {
        e.preventDefault()
      }

      API.post('song/create/' + this.$route.params.id, {
        data: { song: { song: this.newSongTitle } },
        errorMessage: 'There was an error creating your song.',
        rollbarMessage: 'Error creating song',
        validateElem: this.$refs.songform,
        success: data => {
          this.songs.push({ id: data.ID, title: this.newSongTitle, datecompleted: null })
          this.$refs.songTitleInput.reset()
        }
      }, this)
    }
  }
}
</script>
<style>
  .sortHandle { cursor: move; }
</style>