import API from '@/api'

function setLocalStorageViewed (vue, days) {
  vue.$ls.set('newsletter_signup_viewed', false, 60 * 60 * 1000 * 24 * days) // 60 * 60 * 1000 * 24 * days = x days
}

export function hideNewsletter (vue) {
  vue.visible = false
  setLocalStorageViewed(vue, 14)
}

export function showNewsletter (vue) {
  let firstVisit = vue.$ls.get('first_visit', true)
  if (vue.$root.$el.clientWidth > 600 || !firstVisit) {
    return vue.$ls.get('newsletter_signup_viewed', true)
  }

  vue.$ls.set('first_visit', false)

  return false
}

export function subscribeNewsletter (vue) {
  vue.errors = []
  vue.messages = []
  API.post('newsletter/signup/ebook', {
    auth: false,
    data: { name: vue.name, email: vue.email },
    rollbarMessage: 'Error subscribing ' + vue.email + ' to newsletter',
    success: (data) => {
      vue.visible = true
      vue.subscribed = true
      setLocalStorageViewed(vue, 900)
    }
  }, this)
}
