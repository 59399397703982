<template>
   <v-app light>
    <toolbar app :accountArea="accountArea"></toolbar>

    <v-content :class="{ 'home': $route.name === 'Home' }">
      <v-container :class="{ 'login': $route.name === 'LogIn' }" fluid>
        <router-view></router-view>
        <snackbar></snackbar>
      </v-container>
    </v-content>
    <site-footer app :accountArea="accountArea"></site-footer>
    <quick-nav :accountArea="accountArea"></quick-nav>
  </v-app>
</template>

<script>
import { SiteFooter, Snackbar, Toolbar } from './components/Shared/'
import QuickNav from './components/Account/Shared/QuickNav'

export default {
  name: 'app',

  components: {
    QuickNav,
    SiteFooter,
    Snackbar,
    Toolbar
  },

  computed: {
    accountArea () {
      return this.$route.meta.requiresAuth === true
    }
  }
}
// add hidden style: https://webaim.org/techniques/css/invisiblecontent/
// color matcher: http://encycolorpedia.com/1d9b72
</script>

<style>
body {
  margin: 0;
}

a{
  color: #007095;
  text-decoration: none;
}
a:hover {
  color: #006080;
}

#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.v-icon.fa {
  font-size: 20px;
}
main {
  margin: 40px;
}
.nl2br {
  white-space: pre-wrap;
}
.snack {
  z-index:400;
}
  
fieldset {
  border: 1px solid #ddd;
  padding: 1.25rem;
  margin: 1.125rem 0;
}
fieldset legend {
  font-weight: bold;
  padding: 0 0.1875rem;
  margin: 0;
  margin-left: -0.1875rem;
}
/*
  .success {
  background-color:#1D9B72 !important;
}*/
</style>
