<template>
  <div>
    <v-layout row>
      <v-flex xs12>
        <h1 class="mb-2 display-2">Sign up for a Free One Week Trial</h1>
        <p>
          Sign up for LyricWell and improve your lyric writing today! If you're having any issues, feel free to <router-link :to="{ name: 'Contact'} ">contact us</router-link>.
        </p>
      </v-flex>
    </v-layout>
    <v-layout row>
      <v-flex xs12>
        <v-form v-if="!paymentProcessorError" ref="form" novalidate v-model="valid">
          <fieldset>
            <legend>Personal Information</legend>
            <v-container grid-list-md>
              <v-layout row wrap>
                <v-flex xs12 sm6>
                  <v-text-field
                    autofocus
                    append-icon="fa-user"
                    label="First Name"
                    v-model="user.firstname"
                    :rules="validate.firstnameRules"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6>
                  <v-text-field
                    append-icon="fa-user"
                    label="Last Name"
                    v-model="user.lastname"
                    :rules="validate.lastnameRules"
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-container>
          </fieldset>
          <fieldset>
            <legend>Account Information</legend>
            <v-container grid-list-md>
              <v-layout row wrap>
                <v-flex xs12 sm6>
                  <v-text-field
                    append-icon="fa-envelope"
                    data-lpignore="true"
                    label="Email"
                    ref="account"
                    type="email"
                    v-model="user.account"
                    :error-messages="accountErrors"
                    :rules="validate.emailRules"
                    @blur="accountValidation"
                    @focus="clearAccountErrors"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6>
                  <v-text-field
                    data-lpignore="true"
                    label="Password"
                    ref="password"
                    v-model="user.password"
                    :append-icon="hidePassword ? 'fa-eye' : 'fa-eye-slash'"
                    :error-messages="passwordErrors"
                    :rules="validate.passwordRules"
                    :type="hidePassword ? 'password' : 'text'"
                    @blur="passwordValidation"
                    @click:append="() => (hidePassword = !hidePassword)"
                    @focus="clearPasswordErrors"
                    counter
                  ></v-text-field><!-- @todo: add progress bar to this for 12 char min -->
                </v-flex>
              </v-layout>
            </v-container>
          </fieldset>
          <fieldset>
            <legend>Payment Information</legend>
            <v-container grid-list-md>
              <v-layout row wrap>
                <v-flex xs12>
                  <payment-method
                    :checkPayment="checkPayment"
                    @paymentError="showPaymentError"
                    @paymentProcessorError="showPaymentProcessorError"
                    @resetPayment="clearPaymentCheck"
                    @submitPayment="submit"
                  ></payment-method>
                </v-flex>
              </v-layout>
            </v-container>
          </fieldset>
          <fieldset>
              <legend>Choose an Account Type</legend>
              <v-container grid-list-md>
                <v-layout row wrap>
                  <v-flex xs12 sm6 offset-md2 md4 id="submit-button" @click="selectType('annual', $event)">
                    <v-card class="success white--text text-xs-center" hover raised>
                      <v-card-title class="headline">Annual Subscription</v-card-title>
                      <v-card-text class="title">{{ pricing.annual }} <small>(20% savings!)</small></v-card-text>
                      <v-card-actions class="text-xs-right">
                        <v-btn
                          block
                          class="white--text"
                          flat
                          title="Sign Up"
                          @click="prepSubmit">Sign Up</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-flex>
                  <v-flex xs12 sm6 md4 @click="selectType('monthly', $event)">
                    <v-card class="green lighten-4 text-xs-center" hover>
                      <v-card-title class="headline">Monthly Subscription</v-card-title>
                      <v-card-text class="title">{{ pricing.monthly }}</v-card-text>
                      <v-card-actions class="text-xs-right">
                        <v-btn
                          block
                          flat
                          title="Sign Up"
                          @click="prepSubmit">Sign Up</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-flex>
                  <v-flex xs12 offset-md2 md8>
                    <v-card>
                      <v-list dense subheader>
                        <v-subheader>All Accounts Include</v-subheader>
                        <template v-for="(item, index) in features">
                          <v-list-tile 
                            :key="item.title">
                            <v-list-tile-content>
                              <v-list-tile-title v-html="item.title"></v-list-tile-title>
                            </v-list-tile-content>
                          </v-list-tile>
                          <v-divider
                            v-if="index + 1 < features.length"
                            :key="index"
                          ></v-divider>
                        </template>
                      </v-list>
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-container>
              <vue-recaptcha
                ref="recaptcha"
                sitekey="6Lfl3W8UAAAAAJ5LT1EUINC__THnd0IZOgaOt5kW"
                @verify="onCaptchaVerified"
                @expired="onCaptchaExpired"
                size="invisible">
              </vue-recaptcha>
          </fieldset>
          <small>
            If you aren't satisfied with your subscription, you can easily cancel at any time. Monthly subscriptions will receive a full refund for the current month.
            Annual subscriptions will receive a prorated refund.
          </small>
        </v-form>
        <v-alert color="error" icon="fa-exclamation-triangle" v-model="paymentProcessorError">
          We are currently experiencing issues with our payment processor. Please try again at a later time.
        </v-alert>
      </v-flex>
    </v-layout>
    <product-jsonld></product-jsonld>
  </div>
</template>
<script>
import { PaymentMethod, Validate } from '../../Shared'
import ProductJsonld from './ProductJsonld'
import API from '@/api'
import Auth from '@/auth'
import GTM from '@/gtm'
import VueRecaptcha from 'vue-recaptcha'

export default {
  components: {
    PaymentMethod,
    ProductJsonld,
    Validate,
    VueRecaptcha
  },

  created () {
    GTM.productDetail()
  },

  data: () => ({
    valid: false,
    paymentProcessorError: false,
    features: [
      { title: 'Daily Lyric Writing Prompts', icon: '' },
      { title: 'Lyric Writing Exercises', icon: '' },
      { title: 'Song Editor', icon: '' },
      { title: 'Rhyming Dictionary', icon: '' },
      { title: 'Thesaurus', icon: '' },
      { title: 'Words &amp; Phrases List', icon: '' },
      { title: 'Album &amp; Song Motivation', icon: '' },
      { title: 'Monthly Newsletter with Lyric Writing Information', icon: '' }
    ],
    user: {
      firstname: '',
      lastname: '',
      account: '',
      password: '',
      subscriptionType: 'annual',
      nonce: ''
    },
    pricing: {
      'annual': '$99.95/yr',
      'monthly': '$9.95/mo',
      'annualPrice': 99.95,
      'monthlyPrice': 9.95
    },
    accountErrors: [],
    passwordErrors: [],
    hidePassword: true,
    checkPayment: false,
    // showConfirmation: true,
    validate: Validate
  }),

  head: {
    title: {
      inner: 'Sign up for a Free One Week Trial of LyricWell'
    },
    meta: [
      { n: 'twitter:title', c: 'Sign up for a Free One Week Trial of LyricWell' },
      { n: 'twitter:description', c: 'Sign up for a free one week trial of LyricWell online lyric writing to get access to tools and exercises to help you stay motivated, improve your lyric writing, and finish your songs. Start writing today!' },
      { itemprop: 'name', c: 'Sign up for a Free One Week Trial of LyricWell' },
      { itemprop: 'description', c: 'Sign up for a free one week trial of LyricWell online lyric writing to get access to tools and exercises to help you stay motivated, improve your lyric writing, and finish your songs. Start writing today!' },
      { p: 'og:title', c: 'Sign up for a Free One Week Trial of LyricWell' },
      { itemprop: 'og:description', c: 'Sign up for a free one week trial of LyricWell online lyric writing to get access to tools and exercises to help you stay motivated, improve your lyric writing, and finish your songs. Start writing today!' },
      { p: 'og:image', c: 'https://lyricwell.com' + require('./../../../../static/img/mic.jpg') }
    ],
    script: [
      { src: '//www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit', async: true, defer: true }
    ]
  },

  methods: {
    clearAccountErrors () {
      if (this.$refs.account.hasError) {
        this.accountErrors = []
      }
    },

    accountValidation () {
      API.post('accounts/exists', {
        auth: false,
        data: { account: this.user.account },
        rollbarMessage: 'Error attempting to validate email address',
        validateElem: this.$refs.account,
        alert: alerts => {
          if (alerts && alerts.Errors && alerts.Errors.Field && alerts.Errors.Field.account) {
            if (alerts.Errors.Field.account === true) {
              this.accountErrors = [ 'An account with that email address already exists' ]
            } else {
              this.accountErrors = alerts.Errors.Field.account
            }
          }
        }
      }, this)
    },

    clearPasswordErrors () {
      if (this.$refs.password.hasError) {
        this.passwordErrors = []
      }
    },

    passwordValidation () {
      if (this.$refs.password.validate()) {
        if (this.user.password === this.user.account) {
          this.passwordErrors = [ 'Your password must not match your email' ]
        } else {
          this.clearPasswordErrors()
        }
      }
    },

    selectType (type, e) {
      this.user.subscriptionType = type
      GTM.addToCart(this.user.subscriptionType)
      this.prepSubmit(e)
    },

    prepSubmit (e) {
      e.preventDefault()
      if (this.$refs.form.validate() && this.accountErrors.length === 0 && this.passwordErrors.length === 0) {
        GTM.checkout(this.user.subscriptionType, 1)
        this.checkPayment = true
      } else {
        this.$store.commit('SET_TOAST_MESSAGE', { message: 'There was an error submitting your information. Please verify that all fields are filled out correctly and try agian.' })
      }
    },

    onCaptchaVerified: function (recaptchaToken) {
      this.$refs.recaptcha.reset()
      Auth.signUp(this, this.user, recaptchaToken)
    },
    onCaptchaExpired: function () {
      this.$refs.recaptcha.reset()
    },

    showPaymentError () {
      this.$store.commit('SET_TOAST_MESSAGE', { message: 'There was an error with your payment method. Please verify your payment information and try again.' })
    },

    showPaymentProcessorError () {
      this.paymentProcessorError = true
    },

    clearPaymentCheck () {
      this.checkPayment = false
    },
    /*

    cancel () {
      this.showConfirmation = false
    },

    showConfirmationDialog (nonce) {
      this.showConfirmation = true
      this.user.nonce = nonce
    },
    */
    submit (nonce) {
      if (nonce !== undefined && nonce !== '') {
        this.$refs.recaptcha.execute()
        this.user.nonce = nonce
      } else {
        this.showPaymentError()
      }
    }
  }
}
</script>