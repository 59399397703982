<template>
  <div>
    <v-progress-linear :indeterminate="true" v-if="!dataLoaded"></v-progress-linear>
    <v-layout row wrap v-if="success">
      <v-flex xs-12>
        <h1 class="mb-2 display-2">Your Password Is Reset</h1>
        <p>
          <router-link :to="{name: 'LogIn'}">Log in</router-link> with your new password.
        </p>
      </v-flex>
    </v-layout>
    <v-layout row wrap v-if="dataLoaded && tokenExpired && !success">
      <v-flex xs-12>
        <h1 class="mb-2 display-2">Token Expired</h1>
        <p>
          Your token is expired. You can request a password reset again <router-link :to="{name:'ResetPasswordRequest'}">here</router-link>.
        </p>
      </v-flex>
    </v-layout>
    <v-layout row wrap v-if="dataLoaded && tokenInvalid && !success">
      <v-flex xs-12>
        <h1 class="mb-2 display-2">Token Invalid</h1>
        <p>
          Your token is invalid. You can request a password reset again <router-link :to="{name:'ResetPasswordRequest'}">here</router-link>.
        </p>
      </v-flex>
    </v-layout>
    <v-layout row wrap v-if="dataLoaded && !tokenInvalid && !tokenExpired && !success">
      <v-flex xs-12>
        <h1 class="mb-2 display-2">Reset Your Password</h1>
        <p>
          Enter your new password below.
        </p>
      </v-flex>
    </v-layout>
    <v-layout row v-if="dataLoaded && !tokenInvalid && !tokenExpired && !success">
      <v-flex xs11 sm6 md4 lg3 xl2>
        <v-form v-model="valid" ref="form" novalidate @submit="submit">
          <v-text-field
            data-lpignore="true"
            :append-icon="hidePassword ? 'fa-eye' : 'fa-eye-slash'"
            label="Password"
            v-model="password"
            :rules="validate.passwordRules"
            required
            :type="hidePassword ? 'password' : 'text'"
            @click:append="() => (hidePassword = !hidePassword)"
            counter
          ></v-text-field>
          <v-btn color="primary" type="submit">Reset</v-btn>
        </v-form>
      </v-flex>
      <v-flex xs1 sm6 md8 lg9 xl10></v-flex>
    </v-layout>
  </div>
</template>
<script>
import API from '@/api'
import { Validate } from '../../Shared'

export default {
  data () {
    return {
      password: '',
      dataLoaded: false,
      hidePassword: true,
      success: false,
      tokenExpired: false,
      tokenInvalid: false,
      valid: false,
      validate: Validate
    }
  },

  created () {
    API.post('accounts/istokenexpired', {
      auth: false,
      data: { token: this.$route.query.token },
      errorMessage: 'There was an error validating your token.',
      rollbarMessage: 'Error validating token',
      success: data => {
        if (data.tokenStatus === 'invalid') {
          this.tokenInvalid = true
        } else if (data.tokenStatus === 'expired') {
          this.tokenExpired = true
        }
        this.dataLoaded = true
      }
    }, this)
  },

  head: {
    title: {
      inner: 'Reset Your LyricWell Password'
    }
  },

  methods: {
    submit (e) {
      e.preventDefault()
      API.post('accounts/resetpassword', {
        auth: false,
        data: { account: this.$route.query.email, token: this.$route.query.token, password: this.password },
        errorMessage: 'There was an error resetting your password.',
        rollbarMessage: 'Error resetting password',
        validateElem: this.$refs.form,
        success: data => {
          this.success = true
        }
      }, this)
    }
  }
}
</script>