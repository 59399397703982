export default {
  productImpression (page) {
    window.dataLayer.push({
      'event': 'productImpression',
      'ecommerce': {
        'currencyCode': 'USD',
        'impressions': [
          {
            'name': 'Trial',
            'id': '2',
            'price': '0.00',
            'category': 'Subscription',
            'variant': 'Week to Annual',
            'list': page,
            'position': 1
          },
          {
            'name': 'Trial',
            'id': '1',
            'price': '0.00',
            'category': 'Subscription',
            'variant': 'Week to Monthly',
            'list': page,
            'position': 2
          }
        ]
      }
    })
  },
  productClick (page) {
    window.dataLayer.push({
      'event': 'productClick',
      'ecommerce': {
        'click': {
          'actionField': { 'list': page }, // Optional list property.
          'products': [{
            'name': 'Trial', // Name or ID is required.
            'id': '2',
            'price': '0.00',
            'category': 'Subscription',
            'variant': 'Week to Annual',
            'position': 1
          },
          {
            'name': 'Trial', // Name or ID is required.
            'id': '1',
            'price': '0.00',
            'category': 'Subscription',
            'variant': 'Week to Monthly',
            'position': 1
          }]
        }
      }
    })
  },
  productDetail () {
    window.dataLayer.push({
      'event': 'productDetail',
      'ecommerce': {
        'detail': {
          'products': [{
            'name': 'Trial', // Name or ID is required.
            'id': '2',
            'price': '0.00',
            'category': 'Subscription',
            'variant': 'Week to Annual'
          },
          {
            'name': 'Trial', // Name or ID is required.
            'id': '1',
            'price': '0.00',
            'category': 'Subscription',
            'variant': 'Week to Monthly'
          }]
        }
      }
    })
  },
  addToCart (subscriptionType) {
    window.dataLayer.push({
      'event': 'addToCart',
      'ecommerce': {
        'currencyCode': 'USD',
        'add': {
          'products': [{
            'id': (subscriptionType === 'annual' ? '2' : '1'),
            'name': 'Trial',
            'price': '0.00',
            'category': 'Subscription',
            'variant': (subscriptionType === 'annual' ? 'Week to Annual' : 'Week to Monthly'),
            'quantity': 1
          }]
        }
      }
    })
  },
  checkout (subscriptionType, step) {
    window.dataLayer.push({
      'event': 'checkout',
      'ecommerce': {
        'checkout': {
          'actionField': { 'step': step },
          'products': [{
            'id': (subscriptionType === 'annual' ? '2' : '1'),
            'name': 'Trial',
            'price': '0.00',
            'category': 'Subscription',
            'variant': (subscriptionType === 'annual' ? 'Week to Annual' : 'Week to Monthly'),
            'quantity': 1
          }]
        }
      }
    })
  },
  purchase (subscriptionType, callback) {
    window.dataLayer.push({
      'event': 'purchase',
      'eventCategory': 'Subscription',
      'eventAction': 'Trial',
      'eventLabel': (subscriptionType === 'annual' ? 'Annual' : 'Monthly'),
      'eventValue': (subscriptionType === 'annual' ? '7' : '5'),
      'eventCallback': callback,
      'ecommerce': {
        'purchase': {
          'actionField': {
            'id': Date.now(), // Transaction ID. Required for purchases and refunds.
            'affiliation': 'LyricWell',
            'revenue': '0.00' // Total transaction value (incl. tax and shipping)
            // 'tax':'0.00'
            // 'coupon': 'SUMMER_SALE'
          },
          'products': [{
            'id': (subscriptionType === 'annual' ? '2' : '1'),
            'name': 'Trial',
            'price': '0.00',
            'category': 'Subscription',
            'variant': (subscriptionType === 'annual' ? 'Week to Annual' : 'Week to Monthly'),
            'quantity': 1
            /* 'coupon': '' // Optional fields may be omitted or set to empty string. */
          }]
        }
      }
    })
  }
}
