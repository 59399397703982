import Auth from '@/auth'
import Fetch from 'fetchival' // https://github.com/typicode/fetchival

export default {
  API: class API {
    constructor (url, settings, vue, outsideAPI) {
      this.url = 'https://' + window.location.hostname + '/' + (!outsideAPI ? 'api/v1/' : '') + url

      this.settings = {
        alert: null, // function to handle alerts
        auth: true, // whether or not the request should contain auth headers
        data: {}, // data to be posted
        error: null,
        errorMessage: '', // error message
        requestSettings: { // request settings for heaeders/response type
          headers: {}
        },
        rollbarMessage: '',
        rollbarLevel: 'warning',
        success: null, // success callback function
        validateElem: null // element to validate before posting
      }
      if (window.location.port === '8081') {
        this.settings.requestSettings.mode = 'cors'
      }

      this.vue = vue
      this.settings = this.mergeDeep(this.settings, settings)
      if (this.settings.auth) {
        this.settings.requestSettings.headers.Authorization = 'Bearer ' + Auth.getToken(this.vue)
      }
    }

    isObject (item) {
      return (item && typeof item === 'object' && !Array.isArray(item))
    }

    // https://stackoverflow.com/questions/27936772/how-to-deep-merge-instead-of-shallow-merge
    mergeDeep (target, ...sources) {
      if (!sources.length) {
        return target
      }
      const source = sources.shift()

      if (this.isObject(target) && this.isObject(source)) {
        for (const key in source) {
          if (this.isObject(source[key]) && key !== 'validateElem' && key !== 'data') {
            if (!target[key]) {
              Object.assign(target, { [key]: {} })
            }
            this.mergeDeep(target[key], source[key])
          } else {
            Object.assign(target, { [key]: source[key] })
          }
        }
      }

      return this.mergeDeep(target, ...sources)
    }

    alerts (alerts) {
      if (typeof this.settings.alert !== 'function') {
        this.vue.$store.commit('HANDLE_API_ALERTS', { Alerts: alerts })
      } else {
        this.settings.alert(alerts)
      }
    }

    success (data) {
      if (typeof this.settings.success === 'function') {
        this.settings.success(data)
      }
    }

    response (response) {
      if (response.Data !== undefined || response.Alerts !== undefined) {
        if (response.Alerts !== undefined) {
          this.alerts(response.Alerts)
        } else {
          if (response.Data.token !== undefined) { // refresh auth token
            Auth.setToken(this.vue, response.Data)
          }
          this.success(response.Data)
        }
      } else {
        this.success(response)
      }
    }

    error (err) {
      if (this.settings.rollbarLevel === 'critical') {
        this.vue.rollbar.critical((this.settings.rollbarMessage !== '' ? this.settings.rollbarMessage : 'Generic Error'), err)
      } else {
        this.vue.rollbar.warning((this.settings.rollbarMessage !== '' ? this.settings.rollbarMessage : 'Generic Error'), err)
      }

      if (this.settings.errorMessage !== '') {
        this.vue.$store.commit('SET_TOAST_MESSAGE', { message: this.settings.errorMessage })
      }

      if (typeof this.settings.error === 'function') {
        this.settings.error()
      }
    }

    get () {
      Fetch(this.url, this.settings.requestSettings
      ).get(
      ).then(response => {
        this.response(response)
      }).catch(err => {
        this.error(err)
      })
    }

    post () {
      if (this.settings.validateElem === null || this.settings.validateElem.validate()) {
        Fetch(this.url, this.settings.requestSettings
        ).post(
          this.settings.data
        ).then(response => {
          this.response(response)
        }).catch(err => {
          this.error(err)
        })
      }
    }

    put () {
      if (this.settings.validateElem === null || this.settings.validateElem.validate()) {
        Fetch(this.url, this.settings.requestSettings
        ).put(
          this.settings.data
        ).then(response => {
          this.response(response)
        }).catch(err => {
          this.error(err)
        })
      }
    }

    delete () {
      if (this.settings.validateElem === null || this.settings.validateElem.validate()) {
        Fetch(this.url, this.settings.requestSettings
        ).delete(
          this.settings.data
        ).then(response => {
          this.response(response)
        }).catch(err => {
          this.error(err)
        })
      }
    }
  },

  get (url, settings, vue, outsideAPI = false) {
    let API = new this.API(url, settings, vue, outsideAPI)
    API.get()
  },

  post (url, settings, vue) {
    let API = new this.API(url, settings, vue)
    API.post()
  },

  put (url, settings, vue) {
    let API = new this.API(url, settings, vue)
    API.put()
  }
}
