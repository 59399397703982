<template>
  <v-form v-model="valid" ref="form" novalidate @submit="update">
    <v-container grid-list-md>
      <v-layout row wrap>
        <v-flex xs12 sm6>
          <v-text-field
            append-icon="fa-user"
            label="First Name"
            v-model="firstname"
            :rules="validate.firstnameRules"
          ></v-text-field>
        </v-flex>
        <v-flex xs12 sm6>
          <v-text-field
            append-icon="fa-user"
            label="Last Name"
            v-model="lastname"
            :rules="validate.lastnameRules"
          ></v-text-field>
        </v-flex>
        <v-flex class="text-xs-right" xs12>
          <v-btn color="primary" type="submit">Update</v-btn>
        </v-flex>
      </v-layout>
    </v-container>
  </v-form>
</template>

<script>
// https://github.com/algolia/vue-instantsearch algolia search component
import API from '@/api'
import { Validate } from '../../Shared'

export default {
  components: {
    Validate
  },

  data () {
    return {
      firstname: this.$store.state.user.firstname,
      lastname: this.$store.state.user.lastname,
      valid: false,
      validate: Validate
    }
  },

  methods: {
    update (e) {
      e.preventDefault()
      API.put('account/name', {
        data: { firstname: this.firstname, lastname: this.lastname },
        errorMessage: 'There was an error updating your name',
        rollbarMessage: 'Error attempting to update name',
        validateElem: this.$refs.form,
        success: data => {
          this.$store.commit('SET_TOAST_MESSAGE', { message: 'Your name has been updated.', color: 'success' })
          let userInfo = {
            user: {
              firstname: this.firstname,
              lastname: this.lastname
            }
          }

          this.$store.commit('SET_USER_INFORMATION', userInfo)
        }
      }, this)
    }
  }
}
</script>