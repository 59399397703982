<template>
  <v-stepper v-model="step" vertical>
    <v-stepper-step :complete="step > 1" step="1">
      Exercise Summary
    </v-stepper-step>
    <v-stepper-content step="1">
      <p v-if="numberOfWords === 1">In this exercise you will be given one random word at a time. From each word you should generate a line of lyric 
         that contains that word. If you get stuck or don't know a word, click new word. Once you've finished a line, 
        click next word. Try to generate as many lyric lines as you can in five minutes.</p>
      <p v-if="numberOfWords === 2">In this exercise you will be given two random words at a time. From each set of words, you should generate a 
         line of lyric that starts with the first word and ends with the second. If you get stuck or don't know a 
        word, click new words. Once you've finished a line, click next words. Try to generate as many lyric lines as 
        you can in ten minutes.</p>
      <p v-if="numberOfWords === 10">In this exercise you will be given ten random words. Your challenge is to write a song using those ten words in 
        fifteen minutes or less. If your having trouble with the first list, you can get a new list of words to work with.</p>
      <v-btn flat :to="{name: 'ExerciseListing'}">Cancel</v-btn>
      <v-btn color="primary" @click="nextStep">Start Now</v-btn>
    </v-stepper-content>
    
    <v-stepper-step :complete="step > 2" step="2">
     Create lyrics <small>({{ minutes }} minutes)</small>
    </v-stepper-step>
    <v-stepper-content step="2">
      <timer :start=startTimer :stop=stopTimer :time=minutes @completed="nextStep"></timer>
      <v-form @submit="addLine">
        <v-text-field
            data-lpignore="true"
            hint="(Enter to add line)"
            v-if="numberOfWords === 1"
            v-model="song.lyrics"
            :label="'Create a line of lyric that contains the word: ' + this.words[0].word + '.'"
            ref="lyrics"
          ></v-text-field>
        <v-text-field
            data-lpignore="true"
            hint="(Enter to add line)"
            v-if="numberOfWords === 2"
            v-model="song.lyrics"
            :label="'Create a line of lyric starting and ending with the words: ' + this.words[0].word + ' ' + this.words[1].word + '.'"
            ref="lyrics"
          ></v-text-field>
        <span v-if="numberOfWords === 10">
          {{ this.words[0].word }}, {{ this.words[1].word }}, {{ this.words[2].word }}, {{ this.words[3].word }}, {{ this.words[4].word }}, {{ this.words[5].word }}, {{ this.words[6].word }}, {{ this.words[7].word }}, {{ this.words[8].word }}, {{ this.words[9].word }}
        </span>
        <v-textarea
            data-lpignore="true"
            v-if="numberOfWords === 10"
            v-model="song.lyrics"
            label="Create a song using the words above"
            ref="lyrics"
          ></v-textarea>
        <v-btn color="primary" @click="addLine" v-if="numberOfWords !== 10">Add Line</v-btn>
        <v-btn @click="getWords" v-if="numberOfWords === 1">Different Word</v-btn>
        <v-btn @click="getWords" v-if="numberOfWords > 1">Different Words</v-btn>
        <v-btn @click="nextStep" v-if="numberOfWords < 10 ">End Exercise</v-btn>
        <v-btn color="primary" @click="nextStep" v-if="numberOfWords === 10">Finish Exercise</v-btn>
      </v-form>
      <template v-for="(line) in lines">
        <div>{{ line }}</div>
      </template>
    </v-stepper-content>

    <v-stepper-step :complete="step > 3" step="3">
      Finish
    </v-stepper-step>
    <v-stepper-content step="3">
      <v-container fluid grid-list-md>
        <v-layout row wrap>
          <v-flex xs12 v-if="numberOfWords < 10">
            Here are all the lines you created. You can add them to an album or your notebook below.            
            <template v-for="(line) in lines">
              <div>{{ line }}</div>
            </template>
          </v-flex>
          <v-flex xs12 v-if="numberOfWords === 10">
            Below is your song. You can save it to an album if you want.
            <div v-html=song.lyrics></div>
          </v-flex>
          <v-flex xs12>
            <v-form ref="form" novalidate @submit="submit">
              <v-flex xs12 sm6 md3><!-- @todo: use combobox (https://vuetifyjs.com/en/components/combobox) to allow new album creation from here -->
                <v-autocomplete
                  append-icon="fa fa-sort-down"
                  data-lpignore="true"
                  :items="albums"
                  v-model="song.albumid"
                  item-text="Album"
                  item-value="ID"
                  label="Album"
                  :rules="validate.albumRules"
                ></v-autocomplete>
              </v-flex>
              <v-flex xs12 md6>
                <v-text-field
                  data-lpignore="true"
                  label="Song Title"
                  v-model="song.song"
                  :rules="validate.songTitleRules"
                ></v-text-field>
              </v-flex>
              <v-flex xs12>
                <p class="text-xs-right">
                  <v-btn color="primary" type="submit">Create</v-btn>    
                </p>
              </v-flex>
            </v-form>
          </v-flex>
        </v-layout>
      </v-container>
    </v-stepper-content>
  </v-stepper>
</template>

<script>
// @todo: add recordings as an option
import API from '@/api'
import Timer from './Timer'
import { Validate } from '../../Shared'

export default {
  components: {
    Timer,
    Validate
  },
  data: () => ({
    albums: [],
    lines: [],
    minutes: 5,
    numberOfWords: 1,
    seconds: 0,
    song: {
      lyrics: '',
      song: ''
    },
    step: 1,
    startTimer: false,
    stopTimer: false,
    validate: Validate,
    words: []
  }),
  head: {
    title: function () {
      if (this.$route.name === 'ExerciseSingleWord') {
        return { inner: 'Single Word Challenge' }
      } else if (this.$route.name === 'ExerciseDoubleWord') {
        return { inner: 'Double Word Challenge' }
      } else {
        return { inner: 'Ten Word Challenge' }
      }
    }
  },
  created () {
    API.get('song/create', {
      rollbarMessage: 'Error getting albums',
      success: data => {
        if (data !== undefined) {
          if (data.Albums.length > 0) {
            this.albums = data.Albums
          }
        }
      }
    }, this)
    this.init()
  },
  methods: {
    addLine (e) {
      e.preventDefault()
      this.lines.push(this.song.lyrics)
      this.song.lyrics = ''
      this.getWords()
    },
    getWords () {
      API.get('wordwizard/randomwords/' + this.numberOfWords, {
        rollbarMessage: 'Error getting words',
        success: data => {
          if (data !== undefined) {
            this.words = data.randomwords
            this.$refs.lyrics.focus()
          }
        }
      }, this)
    },
    init () {
      if (this.$route.name === 'ExerciseSingleWord') {
        this.numberOfWords = 1
        this.minutes = 5
        this.words = [{ word: '' }]
      } else if (this.$route.name === 'ExerciseDoubleWord') {
        this.numberOfWords = 2
        this.minutes = 10
        this.words = [{ word: '' }, { word: '' }]
      } else {
        this.numberOfWords = 10
        this.minutes = 15
        this.words = [{ word: '' }, { word: '' }, { word: '' }, { word: '' }, { word: '' }, { word: '' }, { word: '' }, { word: '' }, { word: '' }, { word: '' }]
      }
    },
    nextStep () {
      this.step++
      if (this.step === 2) {
        this.getWords()
        this.startTimer = true
      } else if (this.step === 3) {
        this.stopTimer = true
        if (this.numberOfWords === 10) {
          this.song.lyrics = this.song.lyrics.replace(/\n/g, '<br />')
        }
      }
    },
    submit (e) {
      e.preventDefault()
      if (this.numberOfWords !== 10) {
        this.song.lyrics = this.lines.join('<br />')
      }
      API.post('song/create/' + this.song.albumid, {
        data: { song: this.song },
        errorMessage: 'There was an error creating your song.',
        rollbarMessage: 'Error creating song',
        validateElem: this.$refs.form,
        success: data => {
          this.$router.push({
            name: 'EditSong',
            params: { id: data.ID }
          })
        }
      }, this)
    }
  }
}
</script>

<style scoped>
  .headline { width: 100%; }
  .headline i { float: right; }
</style>