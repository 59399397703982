<template>
  <div>
    <v-layout row wrap>
      <v-flex xs-12>
        <h1 class="mb-2 display-2">Email Verified</h1>
      </v-flex>
    </v-layout>
    <v-layout row>
      <v-flex xs12>
        Your new email address has been verified. <v-btn :to="{ name: 'Dashboard'}" color="primary">Continue to your account</v-btn>.
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
export default {
  head: {
    title: {
      inner: 'Email Verified'
    }
  }
}
</script>