<template>
  <div>
    <v-layout row>
      <v-flex xs12>
        <h1 class="mb-2 display-2">Contact LyricWell</h1>
        <p v-if="status!=='submitted'">
          Send your comments or questions below.
        </p>
        <p v-else>
          Thank you for contacting us. We will get back to you as needed.
        </p>
      </v-flex>
    </v-layout>
    <v-layout row>
      <v-flex xs12 md8 lg6 xl4>
        <v-form v-if="status!=='submitted'" ref="form" novalidate v-model="valid" @submit="submit">
          <v-container grid-list-md>
            <v-layout row wrap>
              <v-flex xs12 sm6>
                <v-text-field
                  autofocus
                  append-icon="fa-user"
                  data-lpignore="true"
                  label="Name"
                  v-model="name"
                  :rules="validate.nameRules"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6>
                <v-text-field
                  append-icon="fa-envelope"
                  data-lpignore="true"
                  label="Email"
                  v-model="email"
                  :rules="validate.emailRules"
                ></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-textarea
                  append-icon="fa-comments"
                  data-lpignore="true"
                  label="Comments"
                  v-model="comments"
                  :rules="validate.commentRules"
                ></v-textarea>
                <vue-recaptcha
                  ref="recaptcha"
                  sitekey="6Lfl3W8UAAAAAJ5LT1EUINC__THnd0IZOgaOt5kW"
                  @verify="onCaptchaVerified"
                  @expired="onCaptchaExpired"
                  size="invisible">
                </vue-recaptcha>
                <v-btn color="primary" :disabled="status==='submitting'" type="submit">Submit</v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import { Validate } from '../../Shared'
import VueRecaptcha from 'vue-recaptcha'
import API from '@/api'
// https://itnext.io/how-to-use-google-recaptcha-with-vuejs-7756244400da
// https://www.npmjs.com/package/vue-recaptcha

export default {
  components: {
    Validate,
    VueRecaptcha
  },

  data: () => ({
    comments: '',
    email: '',
    name: '',
    status: '',
    valid: false,
    validate: Validate
  }),

  head: {
    title: {
      inner: 'Contact Us'
    },
    meta: [
      { name: 'description', content: 'Contact LyricWell' }
    ],
    script: [
      { src: '//www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit', async: true, defer: true },
      { src: '//widget.manychat.com/358139491060767.js', async: true }
    ]
  },

  methods: {
    submit: function () {
      event.preventDefault()
      if (this.$refs.form.validate()) {
        // this.status = "submitting"
        this.$refs.recaptcha.execute()
      }
    },
    onCaptchaVerified: function (recaptchaToken) {
      this.status = 'submitting'
      this.$refs.recaptcha.reset()
      API.post('email', {
        auth: false,
        data: { name: this.name, email: this.email, comments: this.comments, recaptchaToken: recaptchaToken },
        errorMessage: 'There was an error sending your comments.',
        rollbarMessage: 'Error sending contact form',
        success: data => {
          this.status = 'submitted'
        }
      }, this)
    },
    onCaptchaExpired: function () {
      this.$refs.recaptcha.reset()
    }
  }
}
</script>