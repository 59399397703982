<template>
  <section>
      <v-container>
        <v-layout class="my-5" justify-center row wrap>
          <v-flex xs12 class="mb-2">
            <div class="text-xs-center">
              <h2 class="display-1">Write Lyrics Well</h2>
            </div>
          </v-flex>
          <v-flex xs12 sm5 v-for="post in posts" :key="post.title">
            <v-card class="elevation-0 transparent" :href="post.link">
              <v-card-media contain :src="post.image" height="200"></v-card-media>
              <v-card-title primary-title class="layout justify-center">
                <div class="title">{{ post.title }}</div>
              </v-card-title>
              <v-card-text>
                {{ post.description }}
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex xs12 class="mb-2">
            <div class="text-xs-center">
              <a href="/blog">View All Blog Posts</a>
            </div>
          </v-flex>
        </v-layout>
      </v-container>
    </section>
</template>

<script>
import API from '@/api'

export default {
  name: 'blog-post-list',

  props: {
    postCount: { type: Number, required: true }
  },

  data () {
    return {
      posts: this.$ls.get('blog_posts', { })
    }
  },

  created () {
    /*
    My attempt at getting weekly update Monday at 6:30am
    https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
    const now = new Date()
    const webhostdate = new Date(new Date().toLocaleString('en-US', { timeZone: 'America/New_York' }))
    let nextPost = new Date((webhostdate.getMonth() + 1) + '/' + webhostdate.getDate() + '/' + webhostdate.getFullYear())
    const day = nextPost.getDay() || 7 // Get current day number
    nextPost.setHours(6, 30)
    nextPost = new Date(nextPost.setDate(nextPost.getDate() + 8 - day)) */
    if (this.posts.length === undefined) { // ajax call to get posts that aren't in localStorage
      API.get('rss/feed/2', {
        auth: false,
        rollbarMessage: 'Error getting blog posts',
        success: (data) => {
          this.posts = data.posts
          this.$ls.set('blog_posts', data.posts, 60 * 60 * 1000 * 24) // nextPost.getTime() - now.getTime()) // 6:30 Monday 60 * 60 * 1000 * 24 = 24 hours
        }
      }, this)
    }
  }
}
</script>