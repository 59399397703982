<template>
  <div>
    <v-layout row>
      <v-flex xs12 md8 lg6 xl4>
        <v-form ref="form" novalidate v-model="valid" @submit="submit">
          <fieldset>
            <legend>Contact LyricWell</legend>
            <p v-if="status!=='submitted'">
              Send your comments or questions below.
            </p>
            <p v-else>
              Thank you for contacting us. We will get back to you as needed.
            </p>
            <v-container grid-list-md v-if="status!=='submitted'">
              <v-layout row wrap>
                <v-flex xs12>
                  <v-text-field
                    append-icon="fa-envelope"
                    data-lpignore="true"
                    label="Subject"
                    v-model="subject"
                    :rules="validate.subjectRules"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12>
                  <v-textarea
                    append-icon="fa-comments"
                    data-lpignore="true"
                    label="Comments"
                    v-model="comments"
                    :rules="validate.commentRules"
                  ></v-textarea>
                  <v-btn color="primary" :disabled="status==='submitting'" type="submit">Submit</v-btn>
                </v-flex>
              </v-layout>
            </v-container>
          </fieldset>
        </v-form>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import { Validate } from '../../Shared'
import API from '@/api'
// https://itnext.io/how-to-use-google-recaptcha-with-vuejs-7756244400da
// https://www.npmjs.com/package/vue-recaptcha

export default {
  components: {
    Validate
  },

  data: () => ({
    comments: '',
    status: '',
    subject: '',
    valid: false,
    validate: Validate
  }),

  head: {
    title: {
      inner: 'Contact Us'
    }
  },

  methods: {
    submit: function () {
      event.preventDefault()
      this.status = 'submitting'
      API.post('account-email', {
        data: { comments: this.comments, subject: this.subject },
        errorMessage: 'There was an error sending your comments.',
        rollbarMessage: 'Error sending account contact form',
        validateElem: this.$refs.form,
        success: data => {
          this.status = 'submitted'
        }
      }, this)
    }
  }
}
</script>