<template>
  <div>
    <v-layout row wrap>
      <v-flex sm8 xs12>
        <h1 class="mb-2 display-1">Account Settings</h1>
      </v-flex>
    </v-layout>
    <v-layout class="mb-5" wrap>
      <v-flex xs12>
        <v-expansion-panel>
          <v-expansion-panel-content lazy>
            <div slot="header">Name</div>
            <name></name>
          </v-expansion-panel-content>
          <v-expansion-panel-content>
            <div slot="header">Email Address</div>
            <email></email>
          </v-expansion-panel-content>
          <v-expansion-panel-content>
            <div slot="header">Password</div>
            <password></password>
          </v-expansion-panel-content>
          <v-expansion-panel-content>
            <div slot="header">Cancel Account</div>
            <account></account>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import Account from './Account'
import Email from './Email'
import Name from './Name'
import Password from './Password'

export default {
  head: {
    title: {
      inner: 'Account Settings'
    }
  },
  components: {
    Account,
    Email,
    Name,
    Password
  }
}
</script>