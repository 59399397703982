<template>
  <v-container grid-list-md>
    <add-word-list
      v-on:wordListAdded="onWordListAdded"></add-word-list>
    <router-link :to="{ name: 'AllWordLists'}" v-if="$router.currentRoute.name === 'CreateWordList'">View All</router-link>
    <v-data-table hide-headers hide-actions :items="wordLists">
      <template slot="no-data">
        <td v-if="dataLoaded">Add your first word/phrase</td>
        <td v-if="!dataLoaded">{{ loadingMessage }}</td>
        <td></td>
      </template>
      <template slot="items" slot-scope="props">
        <tr :key="props.item.ID" :class="{ 'hidden': props.item.deleted === true }">
          <td :ref="props.item.ID">
            {{ props.item.WordList }}
          </td>
          <td class="justify-center px-0 text-xs-center" width="100px">
            <delete
              icon="fa-times-circle"
              :id=props.item.ID
              type="wordlist"
              friendlyName="word/phrase"
              v-on:deleted="onWordListDeleted"
              v-on:restored="onWordListRestored"
              ></delete>
            <clipboard :text="props.item.WordList"></clipboard>
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-container>
</template>
<script>
import AddWordList from './AddWordList'
import Clipboard from '@/components/Shared/Clipboard/Clipboard'
import Delete from '../Shared/Delete'
import API from '@/api'

export default {
  components: {
    AddWordList,
    Clipboard,
    Delete
  },

  props: {
    child: { type: Boolean, default: false },
    dataSent: { type: Boolean, default: false },
    data: { type: Array, default: () => [] }
  },

  data () {
    return {
      dataLoaded: this.dataSent,
      loadingMessage: '',
      wordLists: this.data
    }
  },

  head: {
    title: function () {
      if (!this.child) {
        if (this.$router.currentRoute.name === 'AllWordLists') {
          return { inner: 'Word/Phrase List' }
        } else {
          return { inner: 'Add A New Word/Phrase' }
        }
      }
    }
  },

  created () {
    if (!this.child) {
      this.init()
    }
  },

  methods: {
    init () {
      if (this.$router.currentRoute.name === 'AllWordLists') {
        this.loadingMessage = 'Loading words/phrases...'
        API.get('wordlists/all', {
          errorMessage: 'There was an error getting your list of words and phrases.',
          rollbarMessage: 'Error getting wordlists',
          success: data => {
            this.dataLoaded = true
            if (data !== undefined && data.WordLists !== undefined) {
              this.wordLists = data.WordLists
            }
          }
        }, this)
      } else {
        this.loadingMessage = ''
      }
    },
    onWordListAdded (wordList) {
      this.wordLists.unshift(wordList)
      console.log(this.$refs)
    },
    onWordListDeleted (wordListID) {
      this.wordLists.forEach((elem, index) => {
        if (elem.ID === wordListID) {
          // had to change the visible data for hide/show to work
          this.wordLists[index].deleted = true
          this.wordLists[index].WordList = '##deleted##' + this.wordLists[index].WordList
        }
      })
    },
    onWordListRestored (wordListID) {
      this.wordLists.forEach((elem, index) => {
        if (elem.ID === wordListID) {
          this.wordLists[index].WordList = this.wordLists[index].WordList.replace('##deleted##', '')
          this.wordLists[index].deleted = false
        }
      })
    }
  },
  watch: {
    '$route' (to, from) {
      this.init()
    }
  }
}
</script>
<style scoped>
  .hidden{ display: none; }
</style>