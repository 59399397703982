<template>
    <v-list-tile v-if="navItem.routeName" itemprop="url" exact @click="" :to="{ name: navItem.routeName }">
      <v-list-tile-action>
        <v-icon>{{ navItem.icon }}</v-icon>
      </v-list-tile-action>
      <v-list-tile-content>
        <v-list-tile-title itemprop="name">{{ navItem.title }}</v-list-tile-title>
      </v-list-tile-content>
    </v-list-tile>
    <v-list-tile v-else itemprop="url" exact @click="" :to="navItem.route">
      <v-list-tile-action>
        <v-icon>{{ navItem.icon }}</v-icon>
      </v-list-tile-action>
      <v-list-tile-content>
        <v-list-tile-title itemprop="name">{{ navItem.title }}</v-list-tile-title>
      </v-list-tile-content>
    </v-list-tile>
</template>

<script>
export default {
  name: 'nav-tile',

  props: {
    navItem: { type: Object, required: true }
  }
}
</script>